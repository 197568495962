import React, { useState, useEffect } from 'react'

import { Container } from '@mui/material'
import { useLazyGetCmsQuery } from '../../services/cms';
import { CmsResponse } from '../../types/General';
import useTranslation from '../../hooks/Translation';
function AboutUs() {
  const [cmsData, setCmsData] = useState<CmsResponse>();
  const translation = useTranslation() as any;
  const [getCms, { isLoading }] = useLazyGetCmsQuery();
  const fetchCmsData = async () => {
    try {
      const response = await getCms({}).unwrap();

      if (response?.statusCode === 200) {
        setCmsData(response?.data[0]);
      }
    } catch (error: any) {
      console.log(error, "error=========>");
    }
  };

  useEffect(() => {
    fetchCmsData();
  }, []);
  return (
    <div>
      <div className='Terms'>
        <figure className='img-figure'>
          <img className='logo-png' src="/static/images/logo.png" alt="" height={100} width={300} />

        </figure>
      </div>
      <h2 className='Terms-heading'>{translation.about_us.about}</h2>
      <Container maxWidth="lg">
        {/* Lorem ipsum dolor sit amet consectetur, adipisicing elit. Saepe ipsum, expedita nostrum voluptatem corporis velit odio magnam, rem, fuga dolorum tenetur quasi debitis veniam! Inventore accusantium minima, iste veritatis voluptate error aut. Doloremque eligendi pariatur rem asperiores tenetur eum quia ab aspernatur porro expedita fugit enim minus alias, harum ad!

     <ul className='List-item'>
        <li>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam error molestiae fugiat recusandae quasi neque aliquid architecto, vel mollitia ex nulla dolorum! Tenetur ipsum neque repellat magnam quis ratione minus.</li>
        <li>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia ipsam dolorum qui, delectus sequi repellat, quae quibusdam nesciunt soluta ex, excepturi fugit quam id minus deserunt. Mollitia saepe nesciunt ratione!
        </li>
     </ul> */}

        <p
          className={cmsData ? "about_txt" : "about_txt2"}
          dangerouslySetInnerHTML={{
            __html: cmsData
              ? cmsData?.aboutUs
              : translation.about_us.msg,
          }}
        />
      </Container>
    </div>


  )
}

export default AboutUs
