import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import * as Yup from "yup";
import { useFormik } from "formik";
import { usePostSignUpMutation } from "../../services/auth";
import { Loader, STORAGE_KEYS, setToStorage, showError } from "../../constants";
import { useSocialLoginMutation } from "../../services/main";

import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth"
import app from "../../utils/firebaseKeys";
import { useAppDispatch } from "../../hooks/store";
import { setCredentials } from "../../reducers/authSlice";
import useTranslation from "../../hooks/Translation";

const provider = new GoogleAuthProvider()

const auth = getAuth(app)

const SignUp = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [signUpMutation, { isLoading }] = usePostSignUpMutation();

  const translation = useTranslation() as any
  const [email, setEmail] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [confirmShowPassword, setConfirmShowPassword] = useState(false);
  const [socialLogin] = useSocialLoginMutation()

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      passwordConfirmation: "",
      phone: "",
      countryCode: "+91",
    },
    validationSchema: Yup.object({
      email: email
        ? Yup.string()
          .required(translation.sign_in.email.req)
          .matches(
            /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
            translation.sign_in.enter_valid_email
          )
        : Yup.string().notRequired(),
      password: email
        ? Yup.string()
          .label(translation.sign_in.Password)
          .required(translation.sign_in.password_req)
          .min(6, translation.sign_in.password_atleast_6)
          .matches(
            /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
            translation.sign_in.password_validation
          )
        : Yup.string().notRequired(),

      passwordConfirmation: email
        ? Yup.string()
          .oneOf([Yup.ref(translation.sign_in.password)], translation.sign_in.password_match)
          .required(translation.sign_in.confirm_pass_msg)
        : Yup.string().notRequired(),
      phone: email
        ? Yup.string().notRequired()
        : Yup.string()
          .required(translation.sign_in.phone_req)
          .min(6, translation.sign_in.phone_atleast_6)
          .max(16, translation.sign_in.phone_atleast_16),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);
      let body = {
        appKey: new Date().toISOString(),
        ...(email ? { email: formik.values.email } : {}),
        ...(email ? { password: formik.values.password } : {}),
        ...(!email ? { countryCode: formik.values.countryCode } : {}),
        ...(!email ? { phone: formik.values.phone } : {}),
      };
      console.log(body, "body");

      try {
        const response = await signUpMutation(body).unwrap();
        if (response?.statusCode === 200) {
          const data = {
            key: email ? body?.email : body?.phone,
            password: email ? body?.password : "",
            page: "signUp",
            type: email ? "email" : "phone",
            ...(!email ? { countryCode: formik.values.countryCode } : {}),
            // ...(!email ? { countryName: formik.values.countryName } : {}),
          };

          navigate("/verify-otp", { state: data });
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
      }
    },
  });

  // for country picker
  const handleChangePhone = (phone: any, country: any) => {
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
    formik.setFieldValue(
      "countryCode",
      country?.dialCode.includes("+") ? "" : "+" + country?.dialCode
    );
    // formik.setFieldValue("countryName", country?.countryCode.toUpperCase());
  };

  const SignInWithGoogle = async () => {

    try {
      const result = await signInWithPopup(auth, provider)
      const user = result.user
      const deviceId = navigator.userAgent;


      const body = {
        email: user?.email,
        socialId: user?.uid,
        image: user?.photoURL,
        socialType: "GOOGLE",
        fullName: user?.displayName,
        appKey: new Date().toISOString(),

      }
      console.log("body", body)
      const response: any = await socialLogin({
        body
      })
      console.log(response, "res")

      if (response?.data?.statusCode === 200) {
        setToStorage(
          STORAGE_KEYS.token,
          JSON.stringify(response?.data?.data?.token)
        )
        dispatch(
          setCredentials({
            user: response?.data?.data,
            token: response?.data?.data?.token
          })
        )

        if (response?.data?.data?.isProfileComplete) {
          navigate('/dashboard', { replace: true })
        } else {
          navigate('/profile-setup', { state: { type: "email" } })
        }

      }


      console.log(result, "socialLOgin")



    } catch (error: any) {
      console.log(error.code, "error")
    }



  }



  return (
    <div className="authh">
      <Loader isLoad={isLoading} />
      <div className="conta_iner">
        <div className="auth_parnt">
          <div className="auth_left">
            <figure
              style={{ height: "70px", width: "140px", margin: "0 auto" }}
            >
              <img
                src="/static/images/logo.png"
                alt=""
                style={{ height: "100%", width: "100%", objectFit: "contain" }}
              />
            </figure>
            <h2>{translation.sign_in.create_ac}</h2>
            <div className="google_div">
              {email ? (
                <img
                  onClick={() => setEmail(false)}
                  className="google_icon"
                  src="/static/images/call.png"
                  alt=""
                />
              ) : (
                <img
                  onClick={() => setEmail(true)}
                  className="email_fig"
                  src="/static/images/email.png"
                  alt=""
                />
              )}

              <img
                onClick={SignInWithGoogle}
                className="google_icon"
                src="/static/images/google.png"
                alt=""
              />
              <img
                className="google_icon"
                src="/static/images/facebook.png"
                alt=""
              />
            </div>
            <h5>
              {email ? translation.log_in.or_use_email : "phone number"} {" "}
            </h5>
            <form onSubmit={formik.handleSubmit}>
              <div className="auth_form">
                {email ? (
                  <>
                    <FormControl sx={{ mb: 2, width: "100%" }}>
                      <Typography className="custom_label">
                        {translation.sign_in.email}
                      </Typography>
                      <TextField
                        className="text_field"
                        hiddenLabel
                        placeholder={translation.sign_in.email}
                        fullWidth
                        name="email"
                        type="email"
                        variant="outlined"
                        id="email"
                        onChange={(val) => {
                          if (
                            val.target.value === " " ||
                            val.target.value === "."
                          ) {
                          } else {
                            formik.handleChange(val);
                          }
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        helperText={formik.touched.email && formik.errors.email}
                      />
                    </FormControl>
                    <FormControl sx={{ mb: 2, width: "100%" }}>
                      <Typography className="custom_label">{translation.sign_in.Password}</Typography>
                      <TextField
                        className="text_field"
                        hiddenLabel
                        placeholder={translation.sign_in.Password}
                        fullWidth
                        name="password"
                        id="password"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                        helperText={
                          formik.touched.password && formik.errors.password
                        }
                        type={showPassword ? "text" : "password"}
                        variant="outlined"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment className="eye_btn" position="end">
                              <IconButton
                                aria-label={translation.sign_in.toggle_pass}
                                onClick={() => setShowPassword(!showPassword)}
                                onMouseDown={() =>
                                  setShowPassword(!showPassword)
                                }
                                edge="end"
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                    <FormControl sx={{ mb: 2, width: "100%" }}>
                      <Typography className="custom_label">
                        {translation.sign_in.confirm_pass}
                      </Typography>
                      <TextField
                        className="text_field"
                        hiddenLabel
                        placeholder={translation.sign_in.Password}
                        fullWidth
                        name="passwordConfirmation"
                        id="passwordConfirmation"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.passwordConfirmation}
                        helperText={
                          formik.touched.passwordConfirmation &&
                          formik.errors.passwordConfirmation
                        }
                        type={confirmShowPassword ? "text" : "password"}
                        variant="outlined"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment className="eye_btn" position="end">
                              <IconButton
                                aria-label={translation.sign_in.toggle_pass}
                                onClick={() =>
                                  setConfirmShowPassword(!confirmShowPassword)
                                }
                                onMouseDown={() => !confirmShowPassword}
                                edge="end"
                              >
                                {confirmShowPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                  </>
                ) : (
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translation.sign_in.Phone}
                    </Typography>
                    <PhoneInput
                      enableSearch
                      value={formik.values.countryCode + formik.values.phone}
                      country={"us"}
                      inputClass="phoneInput"
                      inputStyle={{ width: "100%" }}
                      buttonClass="phoneBtn"
                      placeholder={translation.sign_in.Phone}
                      onChange={(phone, country) =>
                        handleChangePhone(phone, country)
                      }
                      onBlur={formik.handleBlur}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault(); // Prevent default behavior (form submission)
                          formik.handleSubmit(); // Manually submit the form
                        }
                      }}
                    />
                    {formik.touched.phone && formik.errors.phone ? (
                      <h6 className="err_msg">
                        {formik.touched.phone && formik.errors.phone}
                      </h6>
                    ) : (
                      ""
                    )}
                  </Grid>
                )}

                <Box sx={{ pt: 3 }}>
                  <Button
                    className="btn btn_primary"
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  // onClick={() =>
                  //   navigate("/verify-otp", { state: formik.values.email })
                  // }
                  >
                    Sign Up
                  </Button>
                </Box>
              </div>
            </form>
          </div>
          <div className="auth_ryt">
            <div className="absolute_div">
              <h3>{translation.sign_in.welcome}</h3>
              <p>{translation.sign_in.warning}</p>
              <Box sx={{ pt: 3, display: "flex", justifyContent: "center" }}>
                <Button
                  className="btn"
                  color="primary"
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={() => navigate("/")}
                >
                  {translation.sign_in.signin}
                </Button>
              </Box>
            </div>
            <figure>
              <img src="/static/images/cook1.jpg" alt="" />
            </figure>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
