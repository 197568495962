import {
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  MenuItem,
  NativeSelect,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import useTranslation from "../../hooks/Translation";
const AddNotification = () => {
  const navigate = useNavigate();
  const [receiver, setReceiver] = useState<string>("All");

  const handleReceiverChange = (event: SelectChangeEvent) => {
    setReceiver(event.target.value as string);
  };
  const translation = useTranslation() as any;

  return (
    <>
      <MainContainer>
        <div className="main_loyout">
          <div className="dashboard">
            <h1 className="mn_hdng">{translation.notification.add}</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-notifications");
              }}
            >
              {translation.notification.back}
            </Button>
          </div>
          <Card className="cards">
            <form>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                     {translation.notification.not_title}
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="title"
                      variant="outlined"
                      fullWidth
                      placeholder="Title"
                    ></TextField>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translation.notification.not_msg}
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="message"
                      variant="outlined"
                      fullWidth
                      placeholder="Message"
                    ></TextField>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translation.notification.select_rec}
                    </Typography>
                    {/* <FormControl sx={{ width: "100%" }}>
                      <NativeSelect
                        fullWidth
                        defaultValue={0}
                        variant="outlined"
                        inputProps={{
                          name: "action",
                          id: "uncontrolled-native",
                        }}
                      >
                        <option value={0}>Select</option>
                        <option value={10}>IOS</option>
                        <option value={20}>Android</option>
                        <option value={30}>All</option>
                      </NativeSelect>
                    </FormControl> */}
                    <FormControl sx={{ width: "100%" }}>
                      <Select
                        fullWidth
                        // className="select_div"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={receiver}
                        // multiple
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        displayEmpty
                        onChange={handleReceiverChange}
                      >
                        <MenuItem value="" disabled>
                          {translation.notification.select}
                        </MenuItem>
                        <MenuItem value="All">{translation.notification.all}</MenuItem>
                        <MenuItem value="User 1">User 1</MenuItem>
                        <MenuItem value="User 2">User 2</MenuItem>
                        <MenuItem value="User 3">User 3</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    {translation.notification.save}
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default AddNotification;
