import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  Checkbox,
  FormControl,
  IconButton,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import DeleteIcon from "@mui/icons-material/Delete";
import ReplyIcon from "@mui/icons-material/Reply";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate } from "react-router-dom";
import { isValidInput } from "../../utils/validations";
import { useLazyDeleteQueriesQuery, useLazyGetQueriesQuery } from "../../services/main";
import { showError } from "../../constants";
import moment from "moment";
import { Pagination, WarnModal } from "../../components";
import { handleDelete } from "../../utils/commonFunctions";
import useAuth from "../../hooks/useAuth";
import useTranslation from "../../hooks/Translation";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CustomerSupport = () => {
  const user = useAuth()
  const navigate = useNavigate();
  const translation = useTranslation() as any;
  const [page, setPage] = useState<number>(1)
  const [totalCount, setTotalCount] = useState<number>(0)
  const [getQueries, { isLoading }] = useLazyGetQueriesQuery()
  const [selectedId, setSelectedId] = useState<string>("")
  const [queries, setQueries] = useState<any>()
  const [deleteById] = useLazyDeleteQueriesQuery()
  console.log(user)
  const fetchQueries = async () => {
    try {
      const response: any = await getQueries({
        status: value === 0 ? "RESOLVED" : "PENDING",
        limit: 10,
        page: page,
        search: debouncedSearchTerm.trim()
      })
      if (response?.data?.statusCode === 200) {
        setQueries(response?.data?.data?.tickets)
        setTotalCount(response?.data?.data?.count)
      }
      console.log(response, "response")
    } catch (error: any) {
      showError(error?.data?.message)
    }

  }
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  function createData(
    Name: string,
    Email: string,
    Queries: string,
    Description: string
  ) {
    return { Name, Email, Queries, Description };
  }
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const rows = [
    createData(
      "Abdul Hussian",
      "abdulhussian67@gmail.com",
      "Lorem Ipsum",
      " Lorem Ipsum is simply dummy text.."
    ),
  ];

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    fetchQueries()
  }, [value, page, searchTerm, debouncedSearchTerm])
  let totalPages = Math.ceil(totalCount / 10)
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1>{translation.help_support.heading}</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="basic tabs example"
                className="custom_tabs_links"
              >
                <Tab label={translation.help_support.resolved} {...a11yProps(0)} />
                <Tab label={translation.help_support.pending} {...a11yProps(1)} />
                {/* <Tab label="Booking Related Disputes" {...a11yProps(2)} /> */}
              </Tabs>
              {user?.status === "Accepted" ? (
                <Button
                  className="btn btn_primary"
                  onClick={() => navigate("/help-and-support/add")}
                >
                  {translation.help_support.ticket}
                </Button>
              ) : null}

            </Box>
            <CustomTabPanel value={value} index={0}>
              <Box className="cards_header">
                <SearchBar
                  searchTerm={searchTerm}
                  setDebouncedSearchTerm={setDebouncedSearchTerm}
                  value={searchTerm}
                  onCross={() => setSearchTerm("")}
                  onChange={(val: any) => {
                    if (isValidInput(val.target.value)) {
                      setSearchTerm(val.target.value);
                    }
                  }}
                />
              </Box>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {/* <TableCell align="center">
                        <Checkbox {...label} />
                      </TableCell> */}
                      <TableCell align="center">{translation.help_support.s_no}</TableCell>
                      <TableCell >{translation.help_support.date}</TableCell>
                      <TableCell>{translation.help_support.username}</TableCell>
                      <TableCell>{translation.help_support.mail}</TableCell>
                      <TableCell>{translation.help_support.issue}</TableCell>
                      {/* <TableCell>Description</TableCell> */}
                      <TableCell>{translation.help_support.action}</TableCell>
                    </TableRow>
                  </TableHead>
                  {queries?.length ? (
                    queries?.map((item: any, index: any) => (
                      <TableBody key={item?._id}>
                        <TableRow>
                          <TableCell align="center">
                            {(page - 1) * 10 + index + 1}
                          </TableCell>
                          <TableCell>
                            {moment(item?.createdAt).format("DD/MM/YYYY")}
                          </TableCell>
                          <TableCell>
                            {item?.userName}
                          </TableCell>
                          <TableCell>
                            {item?.email}
                          </TableCell>
                          <TableCell>
                            {item?.issueType}
                          </TableCell>
                          <TableCell>
                            <Box className="table_actions">
                              <IconButton
                                onClick={() =>
                                  navigate("/help-and-support/details/" + item?._id)
                                }
                              >
                                <VisibilityIcon />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  setOpen(true);
                                  setSelectedId(item?._id);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))
                  ) : (
                    <TableBody>
                      <TableCell
                        align="center"
                        colSpan={10}
                        sx={{ color: "#051140" }}
                      >
                        {translation.help_support.no_query}
                      </TableCell>
                    </TableBody>
                  )}

                </Table>
              </TableContainer>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Box className="cards_header">
                <SearchBar
                  searchTerm={searchTerm}
                  setDebouncedSearchTerm={setDebouncedSearchTerm}
                  value={searchTerm}
                  onCross={() => setSearchTerm("")}
                  onChange={(val: any) => {
                    if (isValidInput(val.target.value)) {
                      setSearchTerm(val.target.value);
                    }
                  }}
                />
              </Box>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {/* <TableCell align="center">
                        <Checkbox {...label} />
                      </TableCell> */}
                      <TableCell align="center">{translation.help_support.s_no}</TableCell>
                      <TableCell>{translation.help_support.date}</TableCell>
                      <TableCell>{translation.help_support.username}</TableCell>
                      <TableCell>{translation.help_support.mail}</TableCell>
                      <TableCell>{translation.help_support.issue}</TableCell>
                      {/* <TableCell>Description</TableCell> */}
                      <TableCell>{translation.help_support.action}</TableCell>
                    </TableRow>
                  </TableHead>

                  {queries?.length ? (
                    queries?.map((item: any, index: any) => (

                      <TableBody key={item?._id}>
                        <TableRow>
                          <TableCell align="center">
                            {(page - 1) * 10 + index + 1}
                          </TableCell>
                          <TableCell>
                            {moment(item?.createdAt).format("DD/MM/YYYY")}
                          </TableCell>
                          <TableCell>
                            {item?.userName}
                          </TableCell>
                          <TableCell>
                            {item?.email}
                          </TableCell>
                          <TableCell>
                            {item?.issueType}
                          </TableCell>
                          <TableCell>
                            <Box className="table_actions">
                              <IconButton
                                onClick={() =>
                                  navigate("/help-and-support/details/" + item?._id)
                                }
                              >
                                <VisibilityIcon />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  setOpen(true);
                                  setSelectedId(item?._id);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))
                  ) : (
                    <TableBody>
                      <TableCell
                        align="center"
                        colSpan={10}
                        sx={{ color: "#051140" }}
                      >
                        {translation.help_support.no_query}
                      </TableCell>
                    </TableBody>
                  )}

                </Table>
              </TableContainer>
            </CustomTabPanel>
            {/* <CustomTabPanel value={value} index={2}>
              <Box className="cards_header">
                <SearchBar />
              </Box>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">
                        <Checkbox {...label} />
                      </TableCell>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>Customer Name</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Queries</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row, i) => (
                      <TableRow>
                        <TableCell align="center">
                          <Checkbox {...label} />
                        </TableCell>
                        <TableCell align="center">{i + 1}</TableCell>
                        <TableCell>{row.Name}</TableCell>
                        <TableCell>{row.Email}</TableCell>
                        <TableCell>{row.Queries}</TableCell>
                        <TableCell>{row.Description}</TableCell>
                        <TableCell>
                          <Box className="table_actions">
                            <IconButton onClick={handleClickOpen}>
                              <ReplyIcon />
                            </IconButton>
                            <IconButton>
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CustomTabPanel> */}
          </Box>
        </Card>
        <Pagination
          module={queries}
          page={page}
          totalPages={totalPages}
          onPageChange={onPageChange}
        />

        <WarnModal
          name="Query"
          open={open}
          setOpen={setOpen}
          handleDelete={() => {
            handleDelete(deleteById, selectedId, fetchQueries);
          }}
        />



      </div>

    </MainContainer>
  );
};

export default CustomerSupport;
