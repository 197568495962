import { END_POINTS } from "../constants/url";
import { AdvertisementResponse, ApiResponse } from "../types/General";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  message: string;
};

type PostAddAdvertisementBody = {};

type PostAddPaymentBody = {
  amount: number;
};

type GetTokenParams = {
  limit?: number;
  page?: number;
  title?: string;
  status?: string;
};

type GetAllAdvertisementResponse = {
  count: number;
  advertisements: AdvertisementResponse[];
};
type SearchOptions = {
  status?: string;
  limit?: number;
  search?: string;
  page?: number;
};
export const mainApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    postAddAdvertisement: builder.mutation<
      CommonResponseType & { data: any },
      PostAddAdvertisementBody
    >({
      query: (body) => ({
        url: END_POINTS.addAdvertisement,
        method: "POST",
        body,
      }),
    }),

    getAdvertisementList: builder.query<
      CommonResponseType & { data: GetAllAdvertisementResponse },
      GetTokenParams
    >({
      query: ({ title, status, page, limit }) => ({
        url: `${END_POINTS.getAdvertisement}?search=${title}&status=${status}&page=${page}&limit=${limit}`,
        method: "GET",
      }),
    }),

    deleteAdvertisement: builder.query<
      CommonResponseType & { data: any },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.deleteAdvertisement}/${id}`,
        method: "DELETE",
      }),
    }),

    blockUnblockAdvertisement: builder.mutation<
      CommonResponseType & { data: any },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.blockUnblockAdvertisement}/${id}`,
        method: "PUT",
      }),
    }),
    getAdvertisement: builder.query<
      CommonResponseType & { data: GetAllAdvertisementResponse },
      { id?: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.getAdvertisement}/${id}`,
        method: "GET",
      }),
    }),
    blockUnblock: builder.query<
      CommonResponseType & { data: GetAllAdvertisementResponse },
      { id?: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.blockUnblockAdvertisement}/${id}`,
        method: "GET",
      }),
    }),
    editAdvertisementByID: builder.mutation<
      CommonResponseType & { data: any },
      { id?: string; body?: any }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.editAdvertisement}/${id}`,
        method: "PUT",
        body,
      }),
    }),
    socialLogin: builder.mutation<
      CommonResponseType & { data: any },
      { body?: any }
    >({
      query: ({ body }) => ({
        url: `${END_POINTS.socialLogin}`,
        method: "POST",
        body,
      }),
    }),

    getQueries: builder.query<
      CommonResponseType & { data: any },
      SearchOptions
    >({
      query: ({ status, limit, page, search }) => ({
        url: `${END_POINTS.getQueries}?status=${status}&search=${search}&limit=${limit}&page=${page}`,
        method: "GET",
      }),
    }),
    deleteQueries: builder.query<
      CommonResponseType & { data: any },
      { id?: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.deleteQueries}/${id}`,
        method: "DELETE",
      }),
    }),
    getQueriesId: builder.query<
      CommonResponseType & { data: any },
      { id?: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.getQueriesById}/${id}`,
        method: "GET",
      }),
    }),
    generateTicket: builder.mutation<
      CommonResponseType & { data: any },
      { body?: any }
    >({
      query: ({ body }) => ({
        url: `${END_POINTS.genereateTicket}`,
        method: "POST",
        body,
      }),
    }),
    dashboard: builder.query<CommonResponseType & { data: any }, {}>({
      query: ({}) => ({
        url: `${END_POINTS.dashboard}`,
        method: "GET",
      }),
    }),
    AdsGraph: builder.query<
      CommonResponseType & { data: any },
      { type?: string }
    >({
      query: ({ type }) => ({
        url: `${END_POINTS.getTotalAdGraph}?type=${type}`,
        method: "GET",
      }),
    }),
    paymentsGraph: builder.query<
      CommonResponseType & { data: any },
      { type?: string }
    >({
      query: ({ type }) => ({
        url: `${END_POINTS.getTotalPaymentsGraph}?type=${type}`,
        method: "GET",
      }),
    }),
    activeGraph: builder.query<
      CommonResponseType & { data: any },
      { type?: string }
    >({
      query: ({ type }) => ({
        url: `${END_POINTS.getTotalActiveAdGraph}?type=${type}`,
        method: "GET",
      }),
    }),
    postAdPayment: builder.mutation<
      CommonResponseType & { data: any },
      PostAddPaymentBody
    >({
      query: (body) => ({
        url: END_POINTS.adPayment,
        method: "POST",
        body,
      }),
    }),

    getReceivedNOtifications:builder.query<
    CommonResponseType & {data:ApiResponse},
    SearchOptions>({
      query:({page,limit,search})=>({
        url:`${END_POINTS.notificationListing}?search=${search}&page=${page}&limit=${limit}`,
        method:"GET"
      })
    })
  }),
});

export const {
  usePostAddAdvertisementMutation,
  useLazyGetAdvertisementListQuery,
  useLazyGetAdvertisementQuery,
  useLazyDeleteAdvertisementQuery,
  useLazyBlockUnblockQuery,
  useEditAdvertisementByIDMutation,
  useSocialLoginMutation,
  useLazyGetQueriesQuery,
  useLazyDeleteQueriesQuery,
  useLazyGetQueriesIdQuery,
  useGenerateTicketMutation,
  useLazyDashboardQuery,
  useLazyAdsGraphQuery,
  useLazyPaymentsGraphQuery,
  useLazyActiveGraphQuery,
  usePostAdPaymentMutation,
  useLazyGetReceivedNOtificationsQuery
} = mainApi;
