import React, { useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
} from "@mui/material";

import PeopleIcon from "@mui/icons-material/People";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import InventoryIcon from "@mui/icons-material/Inventory";
import useTranslation from "../../hooks/Translation";
import LineChart from "../../components/LineChart";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { useNavigate } from "react-router-dom";
import ListAltIcon from "@mui/icons-material/ListAlt";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import SmartDisplayIcon from "@mui/icons-material/SmartDisplay";
import {
  useLazyAdsGraphQuery,
  useLazyDashboardQuery,
  useLazyPaymentsGraphQuery,
} from "../../services/main";
import { showError } from "../../constants";
import FetchAdsData from "../../utils/graphFunctions";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#204e33" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

// function currencyFormat(num:number) {
//   console.log()
//   return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
// }
const NumberFormat = (number: number) => {
  return parseFloat(`${number}`)
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

function convertToInternationalCurrencySystem(labelValue: number) {
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e9
    ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + " B"
    : // Six Zeroes for Millions
    Math.abs(Number(labelValue)) >= 1.0e6
    ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + " M"
    : // Three Zeroes for Thousands
    Math.abs(Number(labelValue)) >= 1.0e3
    ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + " K"
    : Math.abs(Number(labelValue));
}

const DashBoard = () => {
  const [ads, setAds] = useState<any>();
  const translation = useTranslation() as any;
  const { adsData, fetchAdGraphData, paymentData, fetchPaymentGraphData } =
    FetchAdsData();
  // const [adsData,setAdsData]=useState<any>({label:[],dataSet:[]})
  // const [paymentData,setPaymentData]=useState<any>({label:[],dataSet:[]})
  const [getPayments] = useLazyPaymentsGraphQuery();
  const graphOrderData = {
    labels: adsData.label,
    datasets: [
      {
        label: "Ads",
        data: adsData.dataSet,
        borderColor: "#f3bf59",
        backgroundColor: "#f3bf59",
      },
    ],
  };
  const graphRevenueData = {
    labels: paymentData.label,
    datasets: [
      {
        label: "Payments",
        data: paymentData.dataSet,
        borderColor: "#f3bf59",
        backgroundColor: "#f3bf59",
      },
    ],
  };

  const navigate = useNavigate();
  const [dashboard, { isLoading }] = useLazyDashboardQuery();
  const [data, setData] = useState<any>();
  const [studentFilter, setStudentFilter] = useState<string>("Monthly");
  const [earningFilter, setEarningFilter] = useState<string>("Monthly");

  const [getAds] = useLazyAdsGraphQuery();
  const handleOrderChange = (event: SelectChangeEvent) => {
    setStudentFilter(event.target.value as string);
  };

  const handleEarningChange = (event: SelectChangeEvent) => {
    setEarningFilter(event.target.value as string);
  };

  const fetchAdGraph = async () => {
    try {
      const response: any = await getAds({
        type: studentFilter,
      });
      if (response?.data?.statusCode === 200) {
        const newSet = response?.data?.data;
        const label: string[] = [];
        const data: string[] = [];

        newSet.forEach((item: any) => {
          label.push(item.name);
          data.push(item.value);
        });
        // setAdsData({label:label,dataSet:data})
      }
    } catch (error: any) {
      showError(error?.data?.message);
    }
  };

  const fetchPaymentGraph = async () => {
    try {
      const response: any = await getPayments({
        type: earningFilter,
      });
      if (response?.data?.statusCode === 200) {
        const newSet = response?.data?.data;
        const label: string[] = [];
        const data: string[] = [];

        newSet.forEach((item: any) => {
          label.push(item.name);
          data.push(item.value);
        });
        // setPaymentData({label:label,dataSet:data})
      }
    } catch (error: any) {
      showError(error?.data?.message);
    }
  };

  const fetchDetails = async () => {
    try {
      const response: any = await dashboard({}).unwrap();
      if (response?.statusCode === 200) {
        setData(response?.data);
      }
    } catch (error: any) {
      showError(error?.data?.message);
    }
  };
  useEffect(() => {
    fetchDetails();
    // if(studentFilter!==)
  }, []);
  useEffect(() => {
    fetchAdGraphData(studentFilter);
  }, [studentFilter]);
  useEffect(() => {
    fetchPaymentGraphData(earningFilter);
  }, [earningFilter]);

  let number = data?.totalPayments;

  return (
    <div className="main_loyout">
      <div className="dashboard">
        <h1 className="mn_hdng">{translation.dashboard.dashboard}</h1>
      </div>

      <Grid container spacing={2} className="dashGrid">
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            // onClick={() => navigate("/manageusers")}
          >
            <PeopleIcon className="svg_icn" />

            <div>
              <h3>{translation.dashboard.total_Ads}</h3>
              <h4 className="mn_hdng">{data?.totalAdvertisements}</h4>
            </div>
          </Item>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manage-courses")}
          >
            <SmartDisplayIcon className="svg_icn" />

            <div>
              <h3>{translation.dashboard.active_Ads}</h3>
              <h4 className="mn_hdng">{data?.activeAdvertisements}</h4>
            </div>
          </Item>
        </Grid>

        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item className="cards dashGrid_item">
            <AttachMoneyIcon className="svg_icn" />
            <div>
              <h3>{translation.dashboard.payments}</h3>
              <h4 className="mn_hdng">
                {convertToInternationalCurrencySystem(data?.totalPayments || 0)}
              </h4>
            </div>
          </Item>
        </Grid>
        {/* <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item className="cards dashGrid_item">
            <ListAltIcon className="svg_icn" />
            <div>
              <h3>Total Orders</h3>
              <h4 className="mn_hdng">30</h4>
            </div>
          </Item>
        </Grid> */}
      </Grid>

      <Grid container spacing={2} className="dashGraph" sx={{ pt: 4 }}>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Item className="cards dashGraph_item">
            <h2 className="mn_hdng">
              {translation.dashboard.total_add}{" "}
              <FormControl>
                <Select
                  className="select_div"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={studentFilter}
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  displayEmpty
                  onChange={handleOrderChange}
                >
                  <MenuItem value="" disabled>
                    {translation.dashboard.select}
                  </MenuItem>
                  <MenuItem value="Daily">
                    {translation.dashboard.daily}
                  </MenuItem>
                  <MenuItem value="Weekly">
                    {translation.dashboard.weekly}
                  </MenuItem>
                  <MenuItem value="Monthly">
                    {translation.dashboard.monthly}
                  </MenuItem>
                  <MenuItem value="Yearly">
                    {translation.dashboard.yearly}
                  </MenuItem>
                </Select>
              </FormControl>
            </h2>
            <LineChart data={graphOrderData} />
          </Item>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Item className="cards dashGraph_item">
            <h2 className="mn_hdng">
              {translation.dashboard.payments}{" "}
              <FormControl>
                <Select
                  className="select_div"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={earningFilter}
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  displayEmpty
                  onChange={handleEarningChange}
                >
                  <MenuItem value="" disabled>
                    {translation.dashboard.select}
                  </MenuItem>
                  <MenuItem value="Daily">
                    {translation.dashboard.daily}
                  </MenuItem>
                  <MenuItem value="Weekly">
                    {translation.dashboard.weekly}
                  </MenuItem>
                  <MenuItem value="Monthly">
                    {translation.dashboard.monthly}
                  </MenuItem>
                  <MenuItem value="Yearly">
                    {translation.dashboard.yearly}
                  </MenuItem>
                </Select>
              </FormControl>
            </h2>
            <LineChart data={graphRevenueData} />
          </Item>
        </Grid>
      </Grid>
    </div>
  );
};

export default DashBoard;
