import { END_POINTS } from "../constants/url";
import { User } from "../types/User";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  message: string;
};

type PostAuthBody = {
  email?: string;
  phone?: string;
  password?: string;
  countryCode?: string;
};

type PostLoginBody = {
  email?: string;
  password?: string;
  phone?: string;
  countryCode?: string;
  countryName?: string;
};

type PostForgotPasswordBody = {
  email: string;
};

type PostVerifyOtpBody = {
  // type?: string;
  key: string;
  code: string;
};

type PostSetPasswordBody = {
  password: string;
};
type PostSetPasswordResponse = {
  statusCode: number;
  message: string;
  data: any;
};
type PostChangePasswordResponse = {
  statusCode: number;
  message: string;
  data: any;
};
type PostChangePasswordBody = {
  oldPassword: string;
  password: string;
};

type SocialLoginBody = {
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  image: string | null;
  socialId: string | null;
  socialType: string | null;
  deviceType: string | null;
  deviceToken: any;
};

type UpdateProfile = {
  image: string;
  email: string;
  fullName: string;
  phone: string;
  countryCode: string;
  address: string;
  city: string;
  country: string;
  state: string;
  latitude: number;
  longitude: number;
  documentName: string;
  documentExpiryDate: string;
  documentFrontImage: string;
  documentBackImage: string;
};
type PostProfileOtpBody = {
  key: string;
  countryCode?: string;
};

type ProfileOtpBody = {
  key: string;
  countryCode?: string;
  code: string;
};

export const authApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    postSignUp: builder.mutation<
      CommonResponseType & { data: User },
      PostAuthBody
    >({
      query: (body) => ({
        url: END_POINTS.signUp,
        method: "POST",
        body,
      }),
    }),
    postLogin: builder.mutation<
      CommonResponseType & { data: User },
      PostLoginBody
    >({
      query: (body) => ({
        url: END_POINTS.login,
        method: "POST",
        body,
      }),
    }),

    postForgotPassword: builder.mutation<
      CommonResponseType,
      PostForgotPasswordBody
    >({
      query: (body) => ({
        url: END_POINTS.forgotPassword,
        method: "POST",
        body,
      }),
    }),
    // sendOtp: builder.mutation<CommonResponseType, PostForgotPasswordBody>({
    //   query: (body) => ({
    //     url: END_POINTS.sendOtp,
    //     method: "POST",
    //     body,
    //   }),
    // }),
    postVerifyOtp: builder.mutation<
      CommonResponseType & { data: User },
      PostVerifyOtpBody
    >({
      query: (body) => ({
        url: END_POINTS.verifyOtp,
        method: "POST",
        body,
      }),
    }),
    postProfileOtp: builder.mutation<
      CommonResponseType & { data: User },
      PostProfileOtpBody
    >({
      query: (body) => ({
        url: END_POINTS.profileOTP,
        method: "POST",
        body,
      }),
    }),
    postSetPassword: builder.mutation<
      PostSetPasswordResponse,
      PostSetPasswordBody
    >({
      query: (body) => ({
        url: END_POINTS.resetPassword,
        method: "POST",
        body,
      }),
    }),
    postVerifyProfileOtp: builder.mutation<any, ProfileOtpBody>({
      query: (body) => ({
        url: END_POINTS.verifyProfileOtp,
        method: "POST",
        body,
      }),
    }),
    postChangePassword: builder.mutation<
      PostChangePasswordResponse,
      PostChangePasswordBody
    >({
      query: (body) => ({
        url: END_POINTS.changePassword,
        method: "POST",
        body,
      }),
    }),
    getUser: builder.query<CommonResponseType & { data: User }, {}>({
      query: () => ({
        url: END_POINTS.get_user,
        method: "GET",
      }),
    }),
    editProfile: builder.mutation<
      { statusCode: number; data: User; message: string },
      UpdateProfile
    >({
      query: (body) => ({
        url: END_POINTS.update_profile,
        method: "PUT",
        body,
      }),
    }),
    getLogout: builder.query<CommonResponseType, {}>({
      query: () => ({
        url: END_POINTS.logout,
        method: "GET",
      }),
    }),
  }),
});

export const {
  usePostSignUpMutation,
  usePostLoginMutation,
  usePostForgotPasswordMutation,
  useLazyGetUserQuery,
  useEditProfileMutation,
  usePostVerifyOtpMutation,
  usePostSetPasswordMutation,
  usePostChangePasswordMutation,
  useLazyGetLogoutQuery,
  usePostProfileOtpMutation,
  usePostVerifyProfileOtpMutation,
  // useVerifyPhoneEmailMutation,
  // useSendOtpMutation,
  // usePostSocialLoginMutation,
} = authApi;
