export const ar = {
  Globals: {
    Login: "تسجيل الدخول",
    Continue: "متابعة",
    sign_in: "الدخول",
    sign_up: "التسجيل",
    dashboard: "لوحة المعلومات",
    manage_advertisements: "إدارة الإعلانات",
    help_support: "المساعدة والدعم",
    report_analytics: "التقارير والتحليلات",
    settings: "الإعدادات",
    faq: "الأسئلة الشائعة",
  },
  log_in: {
    sign_in: "تسجيل الدخول إلى قائمة المجموعات (باكت لست)",
    or_use_email: "أو استخدم بريدك الإلكتروني للتسجيل:",
    toggle_pass: "تبديل إظهار كلمة المرور",
    password: "كلمة المرور",
    Password: "كلمة المرور",
    password_req: "كلمة المرور مطلوبة!",
    password_atleast_6: "يجب أن تتكون كلمة المرور من 6 أحرف على الأقل",
    phone_num: "رقم الهاتف",
    phone_req: "مطلوب رقم الهاتف",
    phone_atleast6: "يجب أن يتكون رقم الهاتف من 6 أرقام على الأقل",
    phone_atleast16: "يجب أن يتكون رقم الهاتف من 16 رقم على الأقل",
    enter_valid_email: "الرجاء إدخال بريد إلكتروني صحيح",
    forgot_pw: " نسيت كلمة المرور",
    login: "تسجيل الدخول",
    or_use: "أو استخدم ",
    phone: "الهاتف الخاص بك",
    email: "البريد الإلكتروني الخاص بك",
    Email: "البريد الإلكتروني الخاص بك",
    remember_me: "تذكرني",
    remember: "تذكر",
    body: "الهيكل",
    country_code: "رمز الدولة",
    email_req: "البريد الإلكتروني مطلوب",
    log_in: "تسجيل الدخول",
    sign_up: "التسجيل",
    heading: "مرحباً، انضم إلينا",
    desc: "أدخل بياناتك الشخصية وانضم إلى مجمع تطبيق أمبشو",
    search: "ابحث هنا",
  },
  sign_in: {
    email_req: "البريد الإلكتروني مطلوب",
    enter_valid_email: "الرجاء إدخال بريد إلكتروني صحيح",
    Password: "كلمة المرور",
    password: "كلمة المرور",
    phone_num: "رقم الهاتف",
    Phone: " رقم الهاتف",
    password_req: "كلمة المرور مطلوبة!",
    password_atleast_6: "يجب أن تتكون كلمة المرور من 6 أحرف على الأقل.",
    password_validation:
      "يجب أن تحتوي كلمة المرور على 8 أحرف على الأقل، حرف واحد كبير وحرف صغير ورقم واحد وحرف خاص واحد.",
    password_match: "يجب أن تتطابق كلمات المرور.",
    confirm_pass_msg: "يجب تأكيد كلمة المرور.",
    phone_req: "رقم الهاتف مطلوب",
    phone_atleast6: "يجب أن يتكون رقم الهاتف من 6 أرقام على الأقل",
    phone_atleast16: "يجب أن يتكون رقم الهاتف من 16 رقم على الأقل",
    create_ac: "إنشاء حساب",
    email: " عنوان البريد الإلكتروني",
    toggle_pass: "تبديل إظهار كلمة المرور",
    confirm_pass: "تأكيد كلمة المرور",
    sign_up: "التسجيل",
    welcome: "مرحباً بكم مرة أخرى",
    warning: "تم تسجيل الدخول بالفعل، أدخل التفاصيل الخاصة بك وابدأ في الاستكشاف",
    signin: "الدخول",
  },

  dashboard: {
    total_Ads: "إجمالي الإعلانات",
    active_Ads: "إجمالي الإعلانات النشطة",
    payments: "إجمالي المدفوعات",
    total_add: "إجمالي الإعلانات",
    select: "تحديد",
    daily: "اليومية",
    monthly: "الشهرية",
    weekly: "الأسبوعية",
    yearly: "السنوية",
    ads: "الإعلانات",
    payment: "المدفوعات",
    dashboard: "لوحة المعلومات",
  },

  forgot_pass: {
    email_req: "البريد الإلكتروني مطلوب",
    enter_valid_email: "أدخل عنوان بريد إلكتروني صحيح!",
    forgot_pass: "نسيت كلمة المرور",
    email: "عنوان البريد الإلكتروني",
    send: "إرسال",
    welcome: "مرحباً بكم مرة أخرى",
    warning:"تم تسجيل الدخول بالفعل، أدخل التفاصيل الخاصة بك وابدأ في الاستكشاف",
    signin: "الدخول",
  },

  manage_advertisements: {
    accepted: "مقبول",
    pending: "تعليق",
    approved: "تمت الموافقة",
    rejected: "مرفوض",
    add_ad: "إضافة إعلان",
    edit_ad: "تعديل الإعلان",
    s_no: "الرقم المسلسل",
    back: "إلى الخلف",
    advertisement_Image: " صورة الإعلان",
    advertisement_Title: "عنوان الإعلان",
    advertisement_Status: "حالة الإعلان",
    no_ad: "لم يتم العثور على إعلانات",
    web_Link: "رابط الموقع الإلكتروني",
    hours: "عدد الساعات",
    Hours: "عدد الساعات",
    hourly_charge: "التكاليف بالساعة",
    total_cost: "التكلفة الإجمالية",
    save: "حفظ",
    bnr_img: "صورة الشعار",
    created: "تم إنشاؤه في",
    hourly_charge_for_ad: "تكلفة الإعلان بالساعة",
    total: "التكلفة الإجمالية",
    link: "رابط الموقع الإلكتروني",
    created_date: "تاريخ الإنشاء",
    status: "الحالة",
    field_req: "هذا الحقل مطلوب",
    Actions: "الإجراءات",
    error1: "لا يمكن إنشاء سياق كانفا",
    error2: "الرجاء تحديد ملف صورة صالح (بصيغة png أو jpeg).",
    error3: "يرجى تحميل الصورة أو الفيديو",
    warning1: "الحد الأقصى المسموح به هو 60 حرفاً",
    warning2: "مطلوب حرفين على الأقل",
    update_Toast: "تم تحديث الإعلان بنجاح!",
    add_Toast: "تم تحديث الإعلان بنجاح!",
    view_details: "عرض تفاصيل الإعلان",
    manage_ad: "إدارة الإعلانات",
  },
  help_support: {
    heading: "المساعدة والدعم",
    resolved: "الاستعلامات المحلولة",
    pending: "الاستعلامات المعلقة",
    ticket: "إنشاء تذكرة",
    s_no: "الرقم المسلسل",
    back: "إلى الخلف",
    date: "التاريخ",
    username: "اسم المستخدم",
    mail: "البريد الإلكتروني",
    issue: "نوع المشكلة",
    action: "الإجراء",
    no_query: "لم يتم العثور على استعلامات",
    name: "الاسم مطلوب",
    min2: "مطلوب حرفين على الأقل",
    max10: "الحد الأقصى المسموح به هو 10 أحرف",
    max80: "الحد الأقصى المسموح به هو 80 حرفاً",
    type: "النوع مطلوب",
    msg: "الرسالة مطلوبة",
    email_req: "البريد الإلكتروني مطلو",
    enter_valid_email: "أدخل عنوان بريد إلكتروني صحيح!",
    ticket_toast: "تم إنشاء التذكرة",
    Name: "الاسم ",
    Message: "الرسالة ",
    submit: "تقديم",
    userdetails: "تفاصيل المستخدم",
    querydetails: "تفاصيل الاستعلام",
    contact: "رقم الاتصال",
    keymissing: "المفتاح مفقود في الإجابة",
    querystatus: "حالة الاستعلام",
    revert: "رجوع",
  },

  report_analytics: {
    csv: "تصدير ملف بصيغة قيم مفصولة بفواصل (CSV)",
    total_Ads: "إجمالي الإعلانات",
    active_Ads: "إجمالي الإعلانات النشطة",
    active: "الإعلانات النشطة",
    payments: "إجمالي المدفوعات",
    select: "تحديد",
    monthly: "الشهرية",
    weekly: "الأسبوعية",
    yearly: "السنوية",
    daily: "اليومية",
    ads: "الإعلانات",
    payment: "المدفوعات",
    heading: "التقارير والتحليلات",
  },

  settings: {
    settings: "الإعدادات",
    manage_fee: "إدارة رسوم المعاملة",
    transaction_fee: "رسوم المعاملة",
    manage_savings: "إدارة عمليات النقل والحفظ",
    date: " تاريخ النقل",
    enable_disable: "تمكين/تعطيل واجهة برمجة التطبيقات (API)",
    ACHQ: "تطبيق ACHQ",
    plaid: "تطبيق بلايد",
    save: "حفظ",
  },

  manage_cms: {
    settings: "الإعدادات",
    contact: "اتصل بنا",
    terms: "الأحكام والشروط",
    about: "معلومات عن المنصة",
    email: "البريد الإلكتروني",
    phone: " رقم الهاتف",
  },
  about_us: {
    about: "معلومات عنا",
    msg: "لم يتم العثور على معلومات",
  },
  faq: {
    manage: "إدارة الأسئلة الشائعة",
    add: "أضف أسئلة شائعة",
    error: "لم يتم العثور على قائمة أسئلة شائعة",
    faq: "الأسئلة الشائعة",
  },
  notification: {
    manage: "إدارة الإشعارات",
    add: "إضافة إشعار",
    s_no: "الرقم المسلسل",
    date: "التاريخ",
    title: "العنوان",
    message: "الرسالة",
    delete: "حذف",
    back: "إلى الخلف",
    not_title: "عنوان الإشعار",
    not_msg: "رسالة الإشعار",
    select_rec: "حدد المستلم",
    select: "حدد ",
    all: "الجميع",
    save: "حفظ",
    receive: "الإشعارات المستلمة ",
    name: "اسم العميل",
    email: "البريد الإلكتروني للعميل",
    action: "الإجراء",
  },
  privacy: {
    policy: "سياسة الخصوصية",
    msg: "لم يتم العثور على معلومات",
  },
  profile: {
    otp_toast: "تمت إعادة إرسال كلمة المرور لمرة واحدة بنجاح",
    email_toast: "يرجى التحقق من البريد الإلكتروني أولاً",
    phone_toast: "يرجى التحقق من رقم الهاتف أولاً ",
    profile_created: "تم إنشاء الملف الشخصي بنجاح!",
    update_toast: "تم تحديث الملف الشخصي بنجاح",
    email_req: "البريد الإلكتروني مطلوب",
    error1: "الصورة الأمامية للمستند مطلوبة",
    error2: "الصورة الخلفية للمستند مطلوبة",
    enter_valid_email: "أدخل عنوان بريد إلكتروني صحيح!",
    full_name: "الاسم الكامل",
    full_name_err: "الاسم الكامل مطلوب",
    min2: "مطلوب حرفين على الأقل",
    max80: "الحد الأقصى المسموح به هو 80 حرفاً",
    phone_req: "رقم الهاتف مطلوب",
    min6: "يجب ألا يقل رقم الهاتف عن 6 أحرف",
    max16: "يجب أن لا يقل رقم الهاتف عن 16 حرف",
    address_req: "العنوان مطلوب",
    max100: "الحد الأقصى المسموح به هو 100 حرف",
    city_req: "المدينة / الولاية مطلوبة",
    counrty: "Cالدولة مطلوبة",
    field: "هذا الحقل مطلوب",
    min4: "مطلوب 4 أحرف على الأقل",
    max12: "الحد الأقصى المسموح به هو 12 حرف",
    edit_pro: "تعديل الملف الشخصي",
    img: "الصورة",
    profile: "الملف الشخصي",
    name: "الاسم الكامل",
    email: "البريد الإلكتروني",
    verify: "التحقق",
    phone: " رقم الهاتف",
    address: "العنوان",
    city: "المدينة",
    state: "الولاية",
    place: "المدينة/ الولاية",
    country: "الدولة",
    pin: "رمز التعريف الشخصي/الرمز البريدي",
    upload: "تحميل المستندات",
    doc: "اسم المستند",
    date: "تاريخ انتهاء الصلاحية",
    doc_img1: " الصورة الأمامية للمستند",
    doc_img2: "الصورة الخلفية للمستند ",
    submit: "تقديم",
    verify_otp: "التحقق من كلمة المرور لمرة واحدة",
    resend: "إعادة إرسال كلمة المرور لمرة واحدة",
    msg: "ستنتهي صلاحية رمز التحقق خلال",
  },
  sidebar:{
    dashboard:"لوحة القيادة",
    manage_advertisement:"إدارة الإعلان",
    help_support:"مساعدة و دعم",
    report_analytics:"التقرير والتحليلات",
    settings:"إعدادات",
    faq:"الأسئلة الشائعة"
  },
  search:"ابحث هنا",
  showing:"عرض",
  items:"أغراض",
  myProfile:"ملفي",
  change_password:"تغيير كلمة المرور",
  logout:"تسجيل خروج",
  select_language:"اختار اللغة",
  hello_there:"مرحبًا، انضم إلينا",
  message_page:"أدخل بياناتك الشخصية وانضم إلى مجتمع الطموح"
};
