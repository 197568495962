import { ChangeEvent, useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Input,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  useEditAdvertisementByIDMutation,
  useLazyGetAdvertisementQuery,
  usePostAdPaymentMutation,
  usePostAddAdvertisementMutation,
} from "../../services/main";
import {
  STORAGE_KEYS,
  setToStorage,
  showError,
  showToast,
} from "../../constants";
import { isNumber } from "../../utils/validations";
import useTranslation from "../../hooks/Translation";
import { UploadMedia, UploadVideo } from "../../utils/mediaUpload";
import { AdvertisementResponse } from "../../types/General";

const AddAdvertisement = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [uploads, setUploads] = useState<any>([]);
  const [isImage, setIsImage] = useState(false);
  const translation = useTranslation() as any;
  const [image, setImage] = useState<string | undefined>("");

  const [advertisement, setAdvertisement] = useState<AdvertisementResponse[]>(
    []
  );
  const [getAdvertisementById, { isLoading }] = useLazyGetAdvertisementQuery();

  const [AddAdvertisement] = usePostAddAdvertisementMutation();
  const [adPayment] = usePostAdPaymentMutation();
  const [editAdvertisementById] = useEditAdvertisementByIDMutation();

  const advertisementById = async (id: string) => {
    try {
      const response: any = await getAdvertisementById({
        id: id,
      });
      console.log("get", response?.data);
      if (response?.data?.statusCode === 200) {
        setAdvertisement(response?.data?.data.advertisements || []);
        if (
          response?.data?.data?.advertisements[0]?.uploads[0]?.type === "IMAGE"
        ) {
          setIsImage(true);
          setImage(
            response?.data?.data?.advertisements[0]?.uploads[0]?.link || ""
          );
          setUploads([
            {
              link: response?.data?.data?.advertisements[0]?.uploads[0]?.link,
              type: "IMAGE",
              height: 150,
              width: 200,
              thumbnail:
                response?.data?.data?.advertisements[0]?.uploads[0]?.thumbnail,
            },
          ]);
        } else {
          setIsImage(false);
          setImage(
            response?.data?.data?.advertisements[0]?.uploads[0]?.link || ""
          );
        }
        // setImage(
        //   response?.data?.data?.advertisements[0]?.uploads[0]?.link || ""
        // );
      } else {
        setAdvertisement([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (id) {
      advertisementById(id);
    }
  }, []);

  const generateThumbnail = (file: File): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      const video = document.createElement("video");
      video.muted = true; // Mute the video to avoid potential issues
      video.preload = "metadata";

      // Listen for the loadedmetadata event
      video.addEventListener("loadedmetadata", async () => {
        const duration = Math.min(video.duration, 1); // Ensure duration is at least 1 second
        video.currentTime = duration; // Set the currentTime to 1 second after the video starts

        // Wait for a short interval to ensure the video is loaded and ready
        await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second

        const canvas = document.createElement("canvas");
        canvas.width = 200; // Set thumbnail width
        canvas.height = 150; // Set thumbnail height
        const context = canvas.getContext("2d");
        if (context) {
          context.drawImage(video, 0, 0, canvas.width, canvas.height);
          resolve(canvas.toDataURL());
        } else {
          reject(new Error(translation.manage_advertisements.error));
        }
        video.remove();
        canvas.remove();
      });

      video.addEventListener("error", (error) => {
        reject(error);
      });

      video.src = URL.createObjectURL(file); // Set the video source
      document.body.appendChild(video); // Append the video element to the document to trigger metadata loading
      // Clean up: remove the video and canvas elements
    });
  };
  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";
    console.log(file, "file");

    // Check if a file is selected
    if (file) {
      // Check if the selected file is an image
      if (file.type.startsWith("image/")) {
        const res = await UploadMedia(file);
        if (res?.statusCode === 200) {
          const imageData = {
            link: res?.data,
            thumbnail: res?.data,
            type: "IMAGE",
            height: 150,
            width: 200,
          };
          // setUploads((prevImageDataArray: any) => [
          //   ...prevImageDataArray,
          //   imageData,
          // ]);
          setUploads((prevImageDataArray: any) => [imageData]);

          setImage(res?.data);
          setIsImage(true);
        } else {
          showError(res?.message);
        }
      } else if (file.type.startsWith("video/")) {
        // Handle video upload
        const res = await UploadVideo(file);
        if (res?.statusCode === 200) {
          const thumbnail = await generateThumbnail(file);

          // Convert thumbnail data URL to Blob
          const thumbnailByteCharacters = atob(thumbnail.split(",")[1]);
          const thumbnailByteNumbers = new Array(
            thumbnailByteCharacters.length
          );
          for (let i = 0; i < thumbnailByteCharacters.length; i++) {
            thumbnailByteNumbers[i] = thumbnailByteCharacters.charCodeAt(i);
          }
          const thumbnailByteArray = new Uint8Array(thumbnailByteNumbers);
          const thumbnailBlob = new Blob([thumbnailByteArray], {
            type: "image/jpeg",
          });
          // Upload the video
          // const videoUploadResponse = await UploadVideo(thumbnailBlob);
          const thumbnailUploadResponse = await UploadMedia(thumbnailBlob);
          console.log(thumbnailUploadResponse, "thumbnailUploadResponse");

          // Prepare the video data
          const imageData = {
            link: res?.data,
            thumbnail: thumbnailUploadResponse?.data,
            type: "VIDEO",
            height: 150,
            width: 200,
          };
          // Add the new video data to the existing array
          // setUploads((prevImageDataArray: any) => [
          //   ...prevImageDataArray,
          //   imageData,
          // ]);
          setUploads((prevImageDataArray: any) => [imageData]);

          console.log("resdata", res?.data);
          setImage(res?.data);
          setIsImage(false);
        } else {
          showError(res?.message);
        }
      } else {
        // Display an error message for non-image files
        showError(translation.manage_advertisements.error2);
      }
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: advertisement[0]?.title || "",
      noOfHours: advertisement[0]?.noOfHours || "",
      HourlyCharge: advertisement[0]?.HourlyCharge || "",
      totalCost: advertisement[0]?.totalCost || "",
      webLink: advertisement[0]?.webLink || "",
    },

    validationSchema: Yup.object({
      title: Yup.string()
        .required(translation.manage_advertisements.field_req)
        .max(60, translation.manage_advertisements.warning1)
        .min(2, translation.manage_advertisements.warning2),
      noOfHours: Yup.string().required(
        translation.manage_advertisements.field_req
      ),
      HourlyCharge: Yup.string().required(
        translation.manage_advertisements.field_req
      ),
      totalCost: Yup.string().required(
        translation.manage_advertisements.field_req
      ),
      webLink: Yup.string().required(
        translation.manage_advertisements.field_req
      ),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);

      const paymentBody = {
        amount: Number(values.totalCost),
        appKey: new Date().toISOString(),
      };
      const body = {
        title: values.title,
        noOfHours: Number(values.noOfHours),
        HourlyCharge: Number(values.HourlyCharge),
        totalCost: Number(values.totalCost),
        webLink: values.webLink,
        appKey: new Date().toISOString(),
        uploads: uploads,
      };
      if (!uploads?.length) {
        showError(translation.manage_advertisements.error3);
        return;
      }

      if (id) {
        console.log(id);
        try {
          const response: any = await editAdvertisementById({
            id,
            body,
          });

          if (response?.data?.statusCode === 200) {
            showToast(translation.manage_advertisements.update_Toast);
            navigate("/manage-advertisements");
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
        }
      } else {
        try {
          const response = await adPayment(paymentBody).unwrap();
          if (response?.statusCode === 200) {
            setToStorage(STORAGE_KEYS.adInfo, JSON.stringify(body));
            window.open(response?.data);
            // showToast(translation.manage_advertisements.add_Toast);
            // formik.resetForm();
            // navigate("/manage-advertisements");
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
          console.log(error);
        }
      }
    },
  });

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">
            {id
              ? translation.manage_advertisements.edit_ad
              : translation.manage_advertisements.add_ad}
          </h1>

          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-advertisements");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <form onSubmit={formik.handleSubmit}>
            <CardContent sx={{ p: 1 }}>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translation.manage_advertisements.advertisement_Image}
                  </Typography>
                  {image ? (
                    <div className="upload_image_preview2">
                      {isImage ? (
                        <CardMedia component="img" image={image} alt="photo" />
                      ) : (
                        <div className="upload_image_preview2">
                          <video controls width="100%" height="100%">
                            <source src={image} type="video/mp4" />
                          </video>
                        </div>
                      )}

                      <CancelIcon
                        onClick={() => {
                          setImage("");
                        }}
                      />
                    </div>
                  ) : (
                    <Box className="upload_image_bnr">
                      <label htmlFor="icon-button-file">
                        <Input
                          sx={{ display: "none" }}
                          id="icon-button-file"
                          type="file"
                          inputProps={{
                            accept: ".png, .jpg, .jpeg, .svg,.mp4",
                          }}
                          // onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          //   const files = (event.target as HTMLInputElement)
                          //     .files;
                          //   if (files && files[0].type.includes("image")) {
                          //     setImage(URL.createObjectURL(files[0]));
                          //   }
                          // }}
                          onChange={handleImageUpload}
                        />
                        <Button component="span" className="upload_image_btn">
                          {/* <img
                          src={
                            image
                              ? image
                              : "/static/images/user_placeholder.png"
                          }
                          alt=""
                        /> */}
                          <AddIcon />
                        </Button>
                      </label>
                    </Box>
                  )}
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12} />

                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translation.manage_advertisements.advertisement_Title}
                  </Typography>

                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="title"
                    variant="outlined"
                    className="text_field"
                    fullWidth
                    placeholder={
                      translation.manage_advertisements.advertisement_Title
                    }
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else {
                        formik.handleChange(val);
                      }
                    }}
                    inputProps={{ maxLength: 60 }}
                    onBlur={formik.handleBlur}
                    value={formik.values.title}
                    helperText={formik.touched.title && formik.errors.title}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translation.manage_advertisements.web_Link}
                  </Typography>

                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="webLink"
                    variant="outlined"
                    className="text_field"
                    fullWidth
                    placeholder={translation.manage_advertisements.web_Link}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.webLink}
                    helperText={formik.touched.webLink && formik.errors.webLink}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translation.manage_advertisements.hours}
                  </Typography>

                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="noOfHours"
                    variant="outlined"
                    className="text_field"
                    fullWidth
                    placeholder={translation.manage_advertisements.hours}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isNumber(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.noOfHours}
                    helperText={
                      formik.touched.noOfHours && formik.errors.noOfHours
                    }
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translation.manage_advertisements.hourly_charge}{" "}
                  </Typography>

                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="HourlyCharge"
                    variant="outlined"
                    className="text_field"
                    fullWidth
                    placeholder={
                      translation.manage_advertisements.hourly_charge
                    }
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isNumber(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.HourlyCharge}
                    helperText={
                      formik.touched.HourlyCharge && formik.errors.HourlyCharge
                    }
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {" "}
                    {translation.manage_advertisements.total_cost}
                  </Typography>

                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="totalCost"
                    variant="outlined"
                    className="text_field"
                    fullWidth
                    placeholder={translation.manage_advertisements.total_cost}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isNumber(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.totalCost}
                    helperText={
                      formik.touched.totalCost && formik.errors.totalCost
                    }
                  />
                </Grid>
                {/* <Grid item xs={4}>
                <Typography className="custom_label">
                  Start Date
                </Typography>
                <TextField
                  className="text_field"
                  hiddenLabel
                  type={"date"}
                  name="startDate"
                  variant="outlined"
                  fullWidth
                  id="startDate"
                  // value={formik.values.startDate}
                  // onChange={formik.handleChange}
                  // helperText={
                  //   formik.touched.startDate && formik.errors.startDate
                  // }
                />
              </Grid> */}

                {/* <Grid item xs={4}>
                <Typography className="custom_label">End Date</Typography>
                <TextField
                  className="text_field"
                  hiddenLabel
                  type={"date"}
                  name="endDate"
                  variant="outlined"
                  fullWidth
                  id="endDate"
                  // value={formik.values.endDate}
                  // onChange={formik.handleChange}
                  // helperText={
                  //   formik.touched.endDate && formik.errors.endDate
                  // }
                />
              </Grid> */}
                <br />
              </Grid>

              <div className="form_btn">
                <Button size="large" type="submit" className="btn btn_primary">
                  {translation.manage_advertisements.save}
                </Button>
              </div>
            </CardContent>
          </form>
        </Card>
      </div>
    </MainContainer>
  );
};

export default AddAdvertisement;
