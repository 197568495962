import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { usePostSetPasswordMutation } from "../../../services/auth";
import {
  Loader,
  STORAGE_KEYS,
  removeFromStorage,
  showError,
  showToast,
} from "../../../constants";
import { useAppDispatch } from "../../../hooks/store";
import { temporaryToken } from "../../../reducers/authSlice";

const ResetPassword = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [resetPassword, { isLoading }] = usePostSetPasswordMutation();

  const [email, setEmail] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleshowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      passwordConfirmation: "",
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .label("Password")
        .required("Password is required!")
        .min(6, "Password must be at least 6 characters.")
        .matches(
          /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
          "Password must contain at least 8 characters, one uppercase, one lowercase, one number and one special character. "
        ),

      passwordConfirmation: Yup.string()
        .oneOf([Yup.ref("newPassword")], "Passwords must match.")
        .required("Confirm password is required."),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);

      let body = {
        password: formik.values.newPassword,
        appKey: new Date().toISOString(),
      };
      console.log(body, "body");

      try {
        const response = await resetPassword(body).unwrap();
        if (response?.statusCode === 200) {
          showToast("Password reset successfully" || "");
          removeFromStorage(STORAGE_KEYS.tempToken);
          dispatch(
            temporaryToken({
              tempToken: null,
            })
          );
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
      }
      formik.setSubmitting(false);
      navigate("/", {
        replace: true,
      });
    },
  });

  return (
    <div className="authh">
      <Loader isLoad={isLoading} />
      <div className="conta_iner">
        <div className="auth_parnt">
          <div className="auth_left">
            <figure
              style={{ height: "70px", width: "140px", margin: "0 auto" }}
            >
              <img
                src="/static/images/logo.png"
                alt=""
                style={{ height: "100%", width: "100%", objectFit: "contain" }}
              />
            </figure>
            <h2>Reset Password</h2>
            <Box sx={{ paddingBottom: 5 }}></Box>
            <form onSubmit={formik.handleSubmit}>
              <FormControl sx={{ mb: 2, width: "100%" }}>
                <Typography className="custom_label">Password</Typography>
                <TextField
                  hiddenLabel
                  placeholder="Password"
                  fullWidth
                  margin="normal"
                  name="newPassword"
                  type={showPassword ? "text" : "password"}
                  variant="outlined"
                  className="text_field"
                  id="newPassword"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.newPassword}
                  helperText={
                    formik.touched.newPassword && formik.errors.newPassword
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment className="eye_btn" position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
              <FormControl sx={{ width: "100%" }}>
                <Typography className="custom_label">
                  Confirm Password
                </Typography>
                <TextField
                  sx={{ m: 0 }}
                  hiddenLabel
                  placeholder="Confirm Password"
                  fullWidth
                  margin="normal"
                  name="passwordConfirmation"
                  type={showConfirmPassword ? "text" : "password"}
                  variant="outlined"
                  className="text_field"
                  id="passwordConfirmation"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.passwordConfirmation}
                  helperText={
                    formik.touched.passwordConfirmation &&
                    formik.errors.passwordConfirmation
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment className="eye_btn" position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleshowConfirmPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
              <Box sx={{ pt: 3 }}>
                <Button
                  className="btn btn_primary"
                  color="primary"
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  // onClick={() => navigate("/")}
                >
                  Submit
                </Button>
              </Box>
            </form>
          </div>
          <div className="auth_ryt">
            <div className="absolute_div">
              <h3>Welcome Back</h3>
              <p>Already signed up, enter your details and start exploring</p>
              <Box sx={{ pt: 3, display: "flex", justifyContent: "center" }}>
                <Button
                  className="btn"
                  color="primary"
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={() => navigate("/")}
                >
                  Sign In
                </Button>
              </Box>
            </div>
            <figure>
              <img src="/static/images/cook1.jpg" alt="" />
            </figure>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
