import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Box,
  Button,
  Card,
  IconButton,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchBar from "../../components/SearchBar";
// import { useSearchAdvertiserQuery } from "../../services/main";
import { showError } from "../../constants";
import {
  useLazyBlockUnblockQuery,
  useLazyDeleteAdvertisementQuery,
  useLazyGetAdvertisementListQuery,
} from "../../services/main";
import { isValidInput } from "../../utils/validations";
import { Pagination, WarnModal } from "../../components";
import { AdvertisementResponse } from "../../types/General";
import moment from "moment";
import { handleDelete, updateStatus } from "../../utils/commonFunctions";
import useAuth from "../../hooks/useAuth";
import useTranslation from "../../hooks/Translation";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ManageAdvertisements = () => {
  const user = useAuth();
  const navigate = useNavigate();
  const [advertisement, setAdvertisement] = useState<AdvertisementResponse[]>(
    []
  );
  const translation = useTranslation() as any;
  const [open, setOpen] = useState<boolean>(false);
  const [blockUnblockQuery] = useLazyBlockUnblockQuery();
  const [selectedId, setSelectedId] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [value, setValue] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const [getAdvertisementList, { isLoading }] =
    useLazyGetAdvertisementListQuery();
  const [deleteById] = useLazyDeleteAdvertisementQuery();

  const advertisementList = async () => {
    try {
      const response = await getAdvertisementList({
        title: debouncedSearchTerm.trim(),
        status:
          value === 0
            ? "Accepted"
            : value === 1
            ? "Pending"
            : "Rejected",
        page: page,
        limit: 10,
      });
      if (response?.data?.statusCode === 200) {
        setAdvertisement(response?.data?.data?.advertisements || []);
        setTotalCount(response?.data?.data?.count);
      } else {
        setAdvertisement([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    advertisementList();
  }, [value, searchTerm, debouncedSearchTerm, page]);
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  let totalPages = Math.ceil(totalCount / 10);

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">
            {translation.manage_advertisements.manage_ad}
          </h1>
        </div>
        <Card sx={{ mt: 4 }} className="cards">
          <Box className="custom_tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >
              <Tab
                label={translation.manage_advertisements.approved}
                {...a11yProps(0)}
              />
              <Tab
                label={translation.manage_advertisements.pending}
                {...a11yProps(1)}
              />
              <Tab
                label={translation.manage_advertisements.rejected}
                {...a11yProps(2)}
              />
            </Tabs>
            <CustomTabPanel value={value} index={0}>
              <Box className="cards_header">
                <SearchBar
                  searchTerm={searchTerm}
                  setDebouncedSearchTerm={setDebouncedSearchTerm}
                  value={searchTerm}
                  onCross={() => setSearchTerm("")}
                  onChange={(val: any) => {
                    if (isValidInput(val.target.value)) {
                      setSearchTerm(val.target.value);
                    }
                  }}
                />
                <Box className="cards_header_right">
                  {user?.status === "Accepted" ? (
                    <Button
                      className="btn btn_primary"
                      onClick={() => navigate("/manage-advertisements/add")}
                    >
                      {translation.manage_advertisements.add_ad}
                    </Button>
                  ) : null}
                </Box>
              </Box>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">
                        {translation.manage_advertisements.s_no}
                      </TableCell>
                      <TableCell>
                        {translation.manage_advertisements.advertisement_Image}
                      </TableCell>
                      <TableCell>
                        {translation.manage_advertisements.advertisement_Title}
                      </TableCell>
                      <TableCell>
                        {translation.manage_advertisements.web_Link}
                      </TableCell>
                      <TableCell>
                        {translation.manage_advertisements.created_date}{" "}
                      </TableCell>
                      <TableCell>
                        {translation.manage_advertisements.status}{" "}
                      </TableCell>
                      <TableCell>
                        {translation.manage_advertisements.Actions}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {advertisement.length ? (
                    advertisement?.map((item, index) => (
                      <TableBody key={item?._id}>
                        <TableRow>
                          <TableCell align="center">
                            {(page - 1) * 10 + index + 1}
                          </TableCell>
                          <TableCell>
                            {item?.uploads[0]?.type === "IMAGE" ? (
                              <figure className="bnr_img">
                                <img
                                  src={
                                    item?.uploads[0]?.link ||
                                    "/static/images/post.png"
                                  }
                                  alt=""
                                />
                              </figure>
                            ) : (
                              <figure className="bnr_img">
                                <img
                                  src={
                                    item?.uploads[0]?.thumbnail ||
                                    "/static/images/post.png"
                                  }
                                  alt=""
                                />
                              </figure>
                            )}
                          </TableCell>
                          <TableCell>{item?.title}</TableCell>

                          <TableCell>
                            {" "}
                            <a
                              href={item?.webLink}
                              target="_blank"
                              rel="noreffer"
                            >
                              {item?.webLink}
                            </a>
                          </TableCell>

                          <TableCell>
                            {moment(item?.createdAt).format("DD/MM/YYYY")}
                          </TableCell>

                          <TableCell>
                            <Switch
                              onChange={() => {
                                updateStatus(
                                  item?._id,
                                  !item?.isBlocked,
                                  blockUnblockQuery,
                                  setAdvertisement,
                                  advertisement
                                );
                              }}
                              {...label}
                              defaultChecked
                              size="small"
                            />
                          </TableCell>
                          <TableCell>
                            <Box className="table_actions">
                              <IconButton
                                onClick={() =>
                                  navigate(
                                    "/manage-advertisements/details/" + item._id
                                  )
                                }
                              >
                                <VisibilityIcon />
                              </IconButton>
                              <IconButton
                                onClick={() =>
                                  navigate(
                                    `/manage-advertisements/edit/${item?._id}`
                                  )
                                }
                              >
                                <ModeEditIcon />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  setOpen(true);
                                  setSelectedId(item?._id);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))
                  ) : (
                    <TableBody>
                      <TableCell
                        align="center"
                        colSpan={10}
                        sx={{ color: "#051140" }}
                      >
                        {translation.manage_advertisements.no_ad}
                      </TableCell>
                    </TableBody>
                  )}
                  {/* <TableBody>
                    <TableRow>
                      <TableCell align="center">1</TableCell>
                      <TableCell>
                        {" "}
                        <figure className="bnr_img">
                          <img
                            src="/static/images/profile_placeholder.jpg"
                            alt=""
                          />
                        </figure>
                      </TableCell>
                      <TableCell>See the Northern Lights</TableCell>

                      <TableCell>www.northernlights.com</TableCell>

                      <TableCell>
                        <Switch {...label} defaultChecked size="small" />
                      </TableCell>
                      <TableCell>
                        <Box className="table_actions">
                          <IconButton
                            onClick={() =>
                              navigate("/manage-advertisements/details/")
                            }
                          >
                            <VisibilityIcon />
                          </IconButton>
                          <IconButton
                          // onClick={() => navigate("/manageusers/usersform")}
                          >
                            <ModeEditIcon />
                          </IconButton>
                          <IconButton>
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody> */}
                </Table>
              </TableContainer>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Box className="cards_header">
                <SearchBar
                  searchTerm={searchTerm}
                  setDebouncedSearchTerm={setDebouncedSearchTerm}
                  value={searchTerm}
                  onCross={() => setSearchTerm("")}
                  onChange={(val: any) => {
                    if (isValidInput(val.target.value)) {
                      setSearchTerm(val.target.value);
                    }
                  }}
                />
                <Box className="cards_header_right">
                {user?.status === "Accepted" ? (
                    <Button
                      className="btn btn_primary"
                      onClick={() => navigate("/manage-advertisements/add")}
                    >
                      {translation.manage_advertisements.add_ad}
                    </Button>
                  ) : null}
                </Box>
              </Box>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">
                        {translation.manage_advertisements.s_no}
                      </TableCell>
                      <TableCell>
                        {translation.manage_advertisements.advertisement_Image}
                      </TableCell>
                      <TableCell>
                        {translation.manage_advertisements.advertisement_Title}
                      </TableCell>
                      <TableCell>
                        {translation.manage_advertisements.web_Link}
                      </TableCell>
                      <TableCell>
                        {translation.manage_advertisements.created_date}{" "}
                      </TableCell>
                      <TableCell>
                        {translation.manage_advertisements.Actions}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {advertisement?.length ? (
                    advertisement?.map((advertisement, index) => (
                      <TableBody key={advertisement?._id}>
                        <TableRow>
                          <TableCell align="center">
                            {(page - 1) * 10 + index + 1}
                          </TableCell>
                          <TableCell>
                            {advertisement?.uploads[0]?.type === "IMAGE" ? (
                              <figure className="bnr_img">
                                <img
                                  src={
                                    advertisement?.uploads[0]?.link ||
                                    "/static/images/post.png"
                                  }
                                  alt=""
                                />
                              </figure>
                            ) : (
                              <figure className="bnr_img">
                                <img
                                  src={
                                    advertisement?.uploads[0]?.thumbnail ||
                                    "/static/images/post.png"
                                  }
                                  alt=""
                                />
                              </figure>
                            )}
                          </TableCell>

                          <TableCell>{advertisement?.title}</TableCell>

                          <TableCell>
                            {" "}
                            <a
                              href={advertisement?.webLink}
                              target="_blank"
                              rel="noreffer"
                            >
                              {advertisement?.webLink}
                            </a>
                          </TableCell>

                          <TableCell>
                            {moment(advertisement?.createdAt).format(
                              "DD/MM/YYYY"
                            )}
                          </TableCell>
                          <TableCell>
                            <Box className="table_actions">
                              <IconButton
                                onClick={() =>
                                  navigate(
                                    "/manage-advertisements/details/" +
                                      advertisement._id
                                  )
                                }
                              >
                                <VisibilityIcon />
                              </IconButton>
                              <IconButton
                                onClick={() =>
                                  navigate(
                                    `/manage-advertisements/edit/${advertisement?._id}`
                                  )
                                }
                              >
                                <ModeEditIcon />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  setOpen(true);
                                  setSelectedId(advertisement?._id);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))
                  ) : (
                    <TableBody>
                      <TableCell
                        align="center"
                        colSpan={10}
                        sx={{ color: "#051140" }}
                      >
                        {translation.manage_advertisements.no_ad}
                      </TableCell>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <Box className="cards_header">
                <SearchBar
                  searchTerm={searchTerm}
                  setDebouncedSearchTerm={setDebouncedSearchTerm}
                  value={searchTerm}
                  onCross={() => setSearchTerm("")}
                  onChange={(val: any) => {
                    if (isValidInput(val.target.value)) {
                      setSearchTerm(val.target.value);
                    }
                  }}
                />
                <Box className="cards_header_right">
                {user?.status === "Accepted" ? (
                    <Button
                      className="btn btn_primary"
                      onClick={() => navigate("/manage-advertisements/add")}
                    >
                      {translation.manage_advertisements.add_ad}
                    </Button>
                  ) : null}
                </Box>
              </Box>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">
                        {translation.manage_advertisements.s_no}
                      </TableCell>
                      <TableCell>
                        {translation.manage_advertisements.advertisement_Image}
                      </TableCell>
                      <TableCell>
                        {translation.manage_advertisements.advertisement_Title}
                      </TableCell>
                      <TableCell>
                        {translation.manage_advertisements.web_Link}
                      </TableCell>
                      <TableCell>
                        {translation.manage_advertisements.created_date}{" "}
                      </TableCell>
                      <TableCell>
                        {translation.manage_advertisements.Actions}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {advertisement?.length ? (
                    advertisement?.map((advertisement, index) => (
                      <TableBody key={advertisement?._id}>
                        <TableRow>
                          <TableCell align="center">
                            {(page - 1) * 10 + index + 1}
                          </TableCell>
                          <TableCell>
                            {advertisement?.uploads[0]?.type === "IMAGE" ? (
                              <figure className="bnr_img">
                                <img
                                  src={
                                    advertisement?.uploads[0]?.link ||
                                    "/static/images/post.png"
                                  }
                                  alt=""
                                />
                              </figure>
                            ) : (
                              <figure className="bnr_img">
                                <img
                                  src={
                                    advertisement?.uploads[0]?.thumbnail ||
                                    "/static/images/post.png"
                                  }
                                  alt=""
                                />
                              </figure>
                            )}
                          </TableCell>
                          <TableCell>{advertisement?.title}</TableCell>

                          <TableCell>
                            {" "}
                            <a
                              href={advertisement?.webLink}
                              target="_blank"
                              rel="noreffer"
                            >
                              {advertisement?.webLink}
                            </a>
                          </TableCell>

                          <TableCell>
                            {moment(advertisement?.createdAt).format(
                              "DD/MM/YYYY"
                            )}
                          </TableCell>
                          <TableCell>
                            <Box className="table_actions">
                              <IconButton
                                onClick={() =>
                                  navigate(
                                    "/manage-advertisements/details/" +
                                      advertisement._id
                                  )
                                }
                              >
                                <VisibilityIcon />
                              </IconButton>
                              <IconButton
                                onClick={() =>
                                  navigate(
                                    `/manage-advertisements/edit/${advertisement?._id}`
                                  )
                                }
                              >
                                <ModeEditIcon />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  setOpen(true);
                                  setSelectedId(advertisement?._id);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))
                  ) : (
                    <TableBody>
                      <TableCell
                        align="center"
                        colSpan={10}
                        sx={{ color: "#051140" }}
                      >
                        {translation.manage_advertisements.no_ad}
                      </TableCell>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </CustomTabPanel>
          </Box>
        </Card>
        <Pagination
          module={advertisement}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
        <WarnModal
          setOpen={setOpen}
          open={open}
          name="advertisement"
          handleDelete={() => {
            handleDelete(deleteById, selectedId, advertisementList);
          }}
        />
      </div>
    </MainContainer>
  );
};

export default ManageAdvertisements;
