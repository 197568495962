import crypto from "crypto-js";

export function generateEncryptedKey() {
  const iv = "9MSkKLOhJcWselB5";

  try {
    const key = crypto.lib.WordArray.random(32);
    const timestamp = Date.now();
    let dataPayload = { appKey: timestamp };
    let appKey = JSON.stringify(dataPayload);
    const encrypted = crypto.AES.encrypt(appKey, key, {
      iv: crypto.enc.Utf8.parse(iv),
      mode: crypto.mode.CBC,
    });
    const encryptedHex = encrypted.ciphertext.toString();
    const keyHash = key.toString();

    return {
      hash: keyHash,
      sek: encryptedHex,
      appKey,
    };
  } catch (error) {
    console.error("", error);
    return null;
  }
}

export function generateEncryptedKeyBody(body: any) {
  const iv = "9MSkKLOhJcWselB5";

  try {
    const key = crypto.lib.WordArray.random(32);

    let appKey = JSON.stringify(body);
    const encrypted = crypto.AES.encrypt(appKey, key, {
      iv: crypto.enc.Utf8.parse(iv),
      mode: crypto.mode.CBC,
    });
    const encryptedHex = encrypted.ciphertext.toString();
    const keyHash = key.toString();

    return {
      hash: keyHash,
      sek: encryptedHex,
    };
  } catch (error) {
    console.error("", error);
    return null;
  }
}
