import React, { useEffect } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Loader,
  STORAGE_KEYS,
  getFromStorage,
  removeFromStorage,
  showError,
  showToast,
} from "../../constants";
import { usePostAddAdvertisementMutation } from "../../services/main";
import useTranslation from "../../hooks/Translation";
import { useNavigate } from "react-router-dom";

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const translation = useTranslation() as any;
  const [AddAdvertisement, { isLoading }] = usePostAddAdvertisementMutation();

  const handleAddAdvertisement = async () => {
    const data = getFromStorage(STORAGE_KEYS.adInfo);
    const bodyData = JSON.parse(`${data}`);
    const body = {
      title: bodyData.title,
      noOfHours: Number(bodyData.noOfHours),
      HourlyCharge: Number(bodyData.HourlyCharge),
      totalCost: Number(bodyData.totalCost),
      webLink: bodyData.webLink,
      appKey: new Date().toISOString(),
      uploads: bodyData.uploads,
    };

    try {
      const response = await AddAdvertisement(body).unwrap();
      if (response?.statusCode === 200) {
        removeFromStorage(STORAGE_KEYS.adInfo);
        showToast(translation.manage_advertisements.add_Toast);
        // formik.resetForm();
        navigate("/manage-advertisements");
      } else {
        showError(response?.message || "");
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
      removeFromStorage(STORAGE_KEYS.adInfo);
      navigate("/manage-advertisements/add");
      console.log(error);
    }
  };

  useEffect(() => {
    handleAddAdvertisement();
  }, []);

  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div />
    </MainContainer>
  );
};

export default PaymentSuccess;
