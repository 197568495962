import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import EditText from "../../components/EditText";
import PhoneInput from "react-phone-input-2";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import useTranslation from "../../hooks/Translation";
import Box from "@mui/material/Box";
import "react-phone-input-2/lib/bootstrap.css";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { useLazyGetCmsQuery } from "../../services/cms";
import { Loader, STORAGE_KEYS, getFromStorage } from "../../constants";
import { CmsResponse } from "../../types/General";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ManageCms = () => {
  const language = getFromStorage(STORAGE_KEYS.language);
  const translation = useTranslation() as any;
  const [value, setValue] = React.useState(0);
  const [cmsData, setCmsData] = useState<CmsResponse>();

  const [getCms, { isLoading }] = useLazyGetCmsQuery();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const fetchCmsData = async () => {
    try {
      const response = await getCms({}).unwrap();

      if (response?.statusCode === 200) {
        setCmsData(response?.data[0]);
      }
    } catch (error: any) {
      console.log(error, "error=========>");
    }
  };

  useEffect(() => {
    fetchCmsData();
  }, []);

  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{translation.manage_cms.settings}</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >
              <Tab label={translation.manage_cms.contact} {...a11yProps(0)} />
              <Tab label={translation.manage_cms.terms} {...a11yProps(1)} />
              <Tab label={translation.manage_cms.about} {...a11yProps(2)} />
              {/* <Tab label="Account" {...a11yProps(3)} />
              <Tab label="Raise Query" {...a11yProps(4)} /> */}
            </Tabs>
            <CustomTabPanel value={value} index={0}>
              <Card sx={{ p: 3 }}>
                <Grid container spacing={2}>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <Typography className="custom_label">{translation.manage_cms.email}</Typography>
                    <Typography component="p">
                      {" "}
                      <a
                        href={cmsData ? `mailto:${cmsData?.email}` : ""}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>{cmsData?.email || ""}</span>
                      </a>
                    </Typography>
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <Typography className="custom_label">
                      {translation.manage_cms.phone}
                    </Typography>
                    <Typography component="p">
                      {" "}
                      <a
                        href={
                          cmsData
                            ? `tel:${cmsData?.countryCode} ${cmsData?.phone}`
                            : ""
                        }
                      >
                        <span>
                          <span> {cmsData?.countryCode || ""}</span>{" "}
                          <span>{cmsData?.phone}</span>
                        </span>
                      </a>
                    </Typography>
                  </Grid>
                </Grid>
                {/* <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div> */}
              </Card>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={1}>
              <Card sx={{ p: 3 }}>
                {/* <Typography className="heading1">Terms & Condition</Typography> */}
                <p
                  className={cmsData ? "about_txt" : "about_txt2"}
                  dangerouslySetInnerHTML={{
                    __html: cmsData
                      ? cmsData?.termsAndConditions
                      : "No information Found",
                  }}
                />
              </Card>
              {/* <form>
                <EditText
                  content={termsAndConditions}
                  setContent={setTermsAndConditions}
                />
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>
              </form> */}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <Card sx={{ p: 3 }}>
                {/* <Typography className="heading1">Terms & Condition</Typography> */}
                <p
                  className={cmsData ? "about_txt" : "about_txt2"}
                  dangerouslySetInnerHTML={{
                    __html: cmsData
                      ? cmsData?.aboutUs
                      : "No information Found",
                  }}
                />
              </Card>
            </CustomTabPanel>
            {/* <CustomTabPanel value={value} index={3}>
              <Card className="notifi">
                <div className="flexDiv">
                  <Typography className="custom_label">
                    <PersonOutlineOutlinedIcon /> <span>Account Status</span>
                  </Typography>
                  <Switch size="small" defaultChecked />
                </div>
              </Card>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={4}>
              <Card sx={{ p: 3 }}>
                <form>
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Typography className="custom_label">
                        Add Query
                      </Typography>
                      <TextField
                        className="text_field"
                        hiddenLabel
                        type={"text"}
                        name="query"
                        variant="outlined"
                        fullWidth
                        placeholder="Query"
                        multiline
                        rows={5}
                      />
                    </Grid>
                  </Grid>
                  <div className="form_btn">
                    <Button
                      size="large"
                      type="submit"
                      className="btn btn_primary"
                    >
                      Submit
                    </Button>
                  </div>
                </form>
              </Card>
            </CustomTabPanel> */}
          </Box>
        </Card>
      </div>
    </MainContainer>
  );
};

export default ManageCms;
