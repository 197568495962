import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import DeleteIcon from "@mui/icons-material/Delete";
import useTranslation from "../../hooks/Translation";
import { useLazyGetReceivedNOtificationsQuery } from "../../services/main";
import { showError } from "../../constants";
import moment from "moment";
import { Event } from "../../types/General";
import { Pagination } from "../../components";

const RecievedNotifications = () => {
  const translation = useTranslation() as any;
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [notification,setNotifications]=useState<Event[]>([])
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  function createData(
    CustomerName: string,
    CustomerUsername: string,
    CustomerEmail: string,
    title: string,
    message: string
  ) {
    return { CustomerName, CustomerUsername, CustomerEmail, title, message };
  }
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const rows = [
    createData(
      "Abdul Hussian",
      "Abdul67",
      "abdulhussian67@gmail.com",
      "Title",
      "Message"
    ),
  ];

  const [getNotifications,{isLoading}]=useLazyGetReceivedNOtificationsQuery()

  const fetchNotification=async()=>{
    try{
      const res=await getNotifications({
        limit:10,
        page:page,
        search:debouncedSearchTerm.trim()
      }).unwrap()
      if(res?.statusCode===200){
        setNotifications(res?.data?.data )
        setTotalCount(res?.data?.total)
      }
    }catch(error:any){
      showError(error?.data?.message)
    }
  }
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  let totalPages = Math.ceil(totalCount / 10);

  useEffect(()=>{
      fetchNotification()
  },[searchTerm,debouncedSearchTerm,page])

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1>{translation.notification.receive}</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar
              searchTerm={searchTerm}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
            // value={searchTerm}
            // onCross={() => setSearchTerm("")}
            // onChange={(val: any) => {
            //   if (isValidInput(val.target.value)) {
            //     setSearchTerm(val.target.value);
            //   }
            // }}
            />
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">{translation.notification.s_no}</TableCell>
                  {/* <TableCell>{translation.notification.name}</TableCell> */}
                  <TableCell>{translation.notification.date}</TableCell>
                  <TableCell>{translation.notification.title}</TableCell>
                  <TableCell>{translation.notification.message}</TableCell>
                  {/* <TableCell>{translation.notification.action}</TableCell> */}
                </TableRow>
              </TableHead>
              {notification?.length?(
                notification?.map((row,index)=>(
                  <TableBody>
                    <TableRow>
                    <TableCell align="center">{(page - 1) * 10 + index + 1}</TableCell>
                    <TableCell>{moment(row?.createdAt).format("DD/MM/YYYY")}</TableCell>
                    <TableCell>{row?.data?._title||""}</TableCell>
                    <TableCell>{row?.data?._message||""}</TableCell>
                    {/* <TableCell>{}</TableCell> */}
                    
                  </TableRow>
                  </TableBody>
                ))
              ):(
                <TableBody>
                <TableCell align="center" colSpan={10} sx={{ color: "#051140" }}>
                  No  notification found
                </TableCell>
              </TableBody>
              )}
            </Table>
          </TableContainer>
        </Card>
      </div>
      <Pagination
      module={notification}
      page={page}
      onPageChange={onPageChange}
      totalPages={totalPages}/>
    </MainContainer>
  );
};

export default RecievedNotifications;
