import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  FormControl,
  Grid,
  Input,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { Autocomplete } from "@react-google-maps/api";
import VerifiedIcon from "@mui/icons-material/Verified";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { ChangeEvent, useEffect, useState } from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import { useLocation, useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import * as Yup from "yup";
import { useFormik } from "formik";
import { isNumber, isString } from "../../utils/validations";
import {
  useEditProfileMutation,
  usePostProfileOtpMutation,
  usePostVerifyProfileOtpMutation,
} from "../../services/auth";
import {
  Loader,
  STORAGE_KEYS,
  getFromStorage,
  showError,
  showToast,
} from "../../constants";
import { UploadMedia } from "../../utils/mediaUpload";
import useAuth from "../../hooks/useAuth";
import OTPInput from "react-otp-input";
import { generateResponsiveStyle } from "../../utils/ModalStyle";
import useTranslation from "../../hooks/Translation";

const otpStyle = {
  width: "50px",
  height: "50px",
  "box-sizing": "border-box",
  margin: "0 auto",
};

const ProfileSetup = () => {
  const user = useAuth();
  const navigate = useNavigate();
  const style = generateResponsiveStyle();
  const location = useLocation();
  const { state } = location;
  const translation = useTranslation() as any;
  const [updateProfile, { isLoading }] = useEditProfileMutation();
  const [image, setImage] = useState<string>("");
  const [frontDocImage, setFrontDocImage] = useState<string>("");
  const [backDocImage, setBackDocImage] = useState<string>("");
  const [phoneVerified, setPhoneVerified] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [error, setError] = useState(false);
  const [otp, setOtp] = useState<string>("");
  const [resend, setResend] = useState(false);
  const [open, setOpen] = useState(false);
  const [countDown, setCountDown] = useState<number>(59);
  const [address,setAddress]=useState<boolean>(false)
  // const [autoComplete,setAutoComplete]=useState(null)
  const [autocomplete, setAutocomplete] = useState(null);
  const [sendProfileOtp] = usePostProfileOtpMutation();
  const [optVerificationMutation] = usePostVerifyProfileOtpMutation();

  const onLoad = (autocompleteObj: any) => {
    console.log(autocompleteObj, "hello");
    setAutocomplete(autocompleteObj);
  };
  const onPlaceChanged = async () => {

    if (autocomplete) {
      let place = await (autocomplete as any).getPlace()
      console.log(place, "place");

      if (place && place.address_components) {
        let address = place.address_components;

        let state,
          city,
          country,
          zip = "";

        address.forEach(function (component: any) {
          let types = component.types;

          if (types.indexOf("locality") > -1 || types.indexOf("administrative_area_level_3") > -1) {
            city = component.long_name;
          }
          if (types.indexOf("postal_code") > -1) {
            zip = component.long_name;
          }
          if (types.indexOf("administrative_area_level_1") > -1) {
            state = component?.long_name || "";
          }
          if (types.indexOf("country") > -1) {
            country = component?.long_name || "";
          }
        });

        var lat = place.geometry.location.lat();
        var lng = place.geometry.location.lng();
        setAddress(true)
        formik.setFieldValue("address", `${place?.formatted_address}`);
        formik.setFieldValue("city", city || "");
        formik.setFieldValue("country", country || "");
        formik.setFieldValue("state", state || "");
        formik.setFieldValue("zipCode", zip || "");
        // formik.setFieldValue("state", state || "");
        formik.setFieldValue("lat", lat || "");
        formik.setFieldValue("lng", lng || "");
      }
    }
  };

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
    imageType: string
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";
    const res = await UploadMedia(file);
    if (res?.statusCode === 200) {
      if (imageType === "profileImage") {
        setImage(res?.data);
      } else if (imageType === "documentFrontImage") {
        setFrontDocImage(res?.data);
      } else {
        setBackDocImage(res?.data);
      }
    } else {
      showError(res?.message);
    }
  };
  console.log(emailVerified, "email");
  console.log(phoneVerified, "phone");
  // for country picker
  const handleChangePhone = (phone: any, country: any) => {
    console.log("country: ", country);
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
    formik.setFieldValue(
      "countryCode",
      country?.dialCode.includes("+") ? "" : "+" + country?.dialCode
    );
    formik.setFieldValue("countryName", country?.countryCode);
  };

  const sendOtp = async () => {
    const body = {
      key: state?.type === "email" ? formik.values.phone : formik.values.email,
      ...(state?.type === "email"
        ? { countryCode: formik.values.countryCode }
        : {}),
      appKey: new Date().toISOString(),
    };

    try {
      const response = await sendProfileOtp(body).unwrap();
      if (response?.statusCode === 200) {
        if (resend) {
          showToast(translation.profile.otp_toast);
        }
        setOpen(true);
        setCountDown(59);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const handleSubmit = async () => {
    if (otp?.length === 4) {
      setError(false);
      const body = {
        key:
          state?.type === "email" ? formik.values.phone : formik.values.email,
        ...(state?.type === "email"
          ? { countryCode: formik.values.countryCode }
          : {}),
        code: otp,
        appKey: new Date().toISOString(),
      };

      try {
        const res = await optVerificationMutation(body).unwrap();
        if (res?.statusCode === 200) {
          setOpen(false);
          setResend(false);
          if (state?.type === "email") {
            setPhoneVerified(true);
          } else {
            setEmailVerified(true);
          }
        }
      } catch (error: any) {
        if (error?.data?.message) {
          showError(error?.data?.message || "");
        }
      }
    } else {
      setError(true);
    }
  };
  // console.log(user?.displayname,"display name")
  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: user?.email || "",
      fullName: user?.fullName || "",
      phone: "",
      countryCode: "+91",
      address: "",
      city: "",
      state: "",
      lat: 0,
      lng: 0,
      country: "",
      documentName: "",
      documentExpiryDate: "",
      zipCode: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required(translation.profile.email_req),
      // .matches(
      //   /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
      //   "Enter a valid email address!"
      // ),
      fullName: Yup.string()
        .required(translation.profile.full_name)
        .min(2, translation.profile.min2)
        .max(80, translation.profile.max80),
      phone: Yup.string()
        .required(translation.profile.phone_req)
        .min(6, translation.profile.min6)
        .max(16, translation.profile.max16),

      address: Yup.string()
        .required(translation.profile.address_req)
        .min(2, translation.profile.min2)
        .max(100, translation.profile.max100),
      city: Yup.string()
        .required(translation.profile.city_req)
        .min(2, translation.profile.min2)
        .max(100, translation.profile.max100),
      state: Yup.string()
        .required(translation.profile.city_req)
        .min(2, translation.profile.min2)
        .max(100, translation.profile.max100),
      country: Yup.string()
        .required(translation.profile.counrty)
        .min(2, translation.profile.min2)
        .max(100, translation.profile.max100),
      zipCode: Yup.string()
        .required(translation.profile.field)
        .min(4, translation.profile.min4)
        .max(12, translation.profile.max12),
      documentName: Yup.string()
        .required(translation.profile.field)
        .min(2, translation.profile.min2)
        .max(80, translation.profile.max80),

      documentExpiryDate: Yup.string().required(translation.profile.field),
    }),

    onSubmit: async (values) => {
      formik.setSubmitting(true);
      const fcmtoken = getFromStorage(STORAGE_KEYS.fcmToken);
      let body = {
        image: image,
        email: values.email,
        fullName: values.fullName,
        phone: values.phone,
        countryCode: values.countryCode,
        address: values.address,
        city: values.city,
        country: values.country,
        state: values.state,
        latitude: values.lat,
        longitude: values.lng,
        zipCode: values.zipCode,
        documentName: values.documentName,
        documentExpiryDate: values.documentExpiryDate,
        documentFrontImage: frontDocImage,
        documentBackImage: backDocImage,
        isProfileComplete: true,
        deviceToken: fcmtoken || "1234",
        deviceType: "WEB",
        appKey: new Date().toISOString(),
      };

      if(!address){
        showError("Please enter a valid address")
        return;
      }

      if (!frontDocImage) {
        showError(translation.profile.error1);
        return;
      } else if (!backDocImage) {
        showError(translation.profile.error2);
        return;
      }
      if (!emailVerified) {
        showError(translation.profile.eail_toast);
        return;
      } else if (!phoneVerified) {
        showError(translation.profile.phone_toast);
        return;
      }
      try {
        const response = await updateProfile(body).unwrap();
        if (response?.statusCode === 200) {
          showToast(translation.profile.profile_created);
          navigate("/dashboard", { replace: true });
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
      }
    },
  });

  useEffect(() => {
    if (state) {
      if (state?.type === "email") {
        formik.setFieldValue("email", state?.key);
        setEmailVerified(true);
      } else {
        formik.setFieldValue("phone", state?.key);
        formik.setFieldValue("countryCode", state?.countryCode);
        // formik.setFieldValue("countryName", state?.countryName);
        setPhoneVerified(true);
      }
    }
  }, [state]);

  useEffect(() => {
    if (open) {
      if (countDown > 0) {
        setTimeout(() => {
          setCountDown(countDown - 1);
        }, 1000);
      } else {
        setCountDown(0);
      }
    }
  }, [countDown, open]);
  useEffect(() => {
    if (user) {
      setImage(user?.image);
      formik.setFieldValue("email", user?.email);
    } else {
      setImage("");
      formik.setFieldValue("email", "");
    }
  }, []);

  console.log(user, "userInfo");

  return (
    <div className="authh p_setup">
      <Loader isLoad={isLoading} />
      <div className="profile_wrap">
        <Card className="dashboard2">
          <h1>{translation.profile.profile}</h1>
        </Card>
        <Card className="cards" style={{ padding: "20px" }}>
          <form onSubmit={formik.handleSubmit}>
            <CardContent sx={{ p: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className="custom_label">
                    {translation.profile.img}
                  </Typography>
                  {image ? (
                    <div className="upload_image_preview">
                      <CardMedia component="img" image={image} alt="photo" />
                      <CancelIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          setImage("");
                        }}
                      />
                    </div>
                  ) : (
                    <Box className="upload_image">
                      <label htmlFor="icon-button-file">
                        <Input
                          sx={{ display: "none" }}
                          id="icon-button-file"
                          type="file"
                          inputProps={{
                            accept: "image/png,image/jpeg",
                          }}
                          onChange={(e) =>
                            handleImageUpload(
                              e as ChangeEvent<HTMLInputElement>,
                              "profileImage"
                            )
                          }
                        />
                        <Button component="span" className="upload_image_btn">
                          <img
                            src={
                              image
                                ? image
                                : "/static/images/user_placeholder.png"
                            }
                            alt=""
                          />
                          <CameraAltIcon />
                        </Button>
                      </label>
                    </Box>
                  )}
                  {error && !image ? (
                    <h6 className="err_msg">{translation.profile.field}</h6>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translation.profile.full_name}
                  </Typography>
                  <TextField
                    className="text_field"
                    hiddenLabel
                    type={"text"}
                    name="fullName"
                    variant="outlined"
                    fullWidth
                    placeholder={translation.profile.full_name}
                    id="fullName"
                    inputProps={{ maxLength: 25 }}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isString(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.fullName}
                    helperText={
                      formik.touched.fullName && formik.errors.fullName
                    }
                  />
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translation.profile.email}
                  </Typography>
                  <div style={{ position: "relative" }}>
                    <TextField
                      hiddenLabel
                      type={"email"}
                      name="email"
                      variant="outlined"
                      fullWidth
                      placeholder={translation.profile.email}
                      id="email"
                      className="text_field"
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      helperText={formik.touched.email && formik.errors.email}
                      disabled={
                        state?.type === "email" ||
                        user?.isEmailVerify ||
                        emailVerified
                          ? true
                          : false
                      }
                    />
                    {formik.values.email &&
                    state?.type === "phone" &&
                    !emailVerified ? (
                      <div className="verifydiv">
                        <h4 onClick={sendOtp}>{translation.profile.verify}</h4>
                      </div>
                    ) : emailVerified ? (
                      <div className="verified">
                        <VerifiedIcon />
                      </div>
                    ) : null}
                  </div>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translation.profile.phone}
                  </Typography>
                  <div style={{ position: "relative" }}>
                    <PhoneInput
                      enableSearch
                      value={formik.values.countryCode + formik.values.phone}
                      country={"us"}
                      inputClass="phoneInput"
                      inputStyle={{ width: "100%" }}
                      buttonClass="phoneBtn"
                      placeholder={translation.profile.phone}
                      onChange={(phone, country) =>
                        handleChangePhone(phone, country)
                      }
                      onBlur={formik.handleBlur}
                      disabled={
                        state?.type === "phone" || phoneVerified ? true : false
                      }
                    />
                    {formik.values.phone &&
                    state?.type !== "phone" &&
                    !phoneVerified ? (
                      <div className="verifydiv">
                        <h4 onClick={sendOtp}>{translation.profile.verify}</h4>
                      </div>
                    ) : phoneVerified ? (
                      <div className="verified">
                        <VerifiedIcon />
                      </div>
                    ) : null}
                    {formik.touched.phone && formik.errors.phone ? (
                      <h6 className="err_msg">
                        {formik.touched.phone && formik.errors.phone}
                      </h6>
                    ) : (
                      ""
                    )}
                  </div>
                </Grid>

                {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Headline</Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="headline"
                    variant="outlined"
                    fullWidth
                    placeholder="Headline"
                    className="text_field"
                    id="headline"
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.headline}
                    helperText={
                      formik.touched.headline && formik.errors.headline
                    }
                  />
                </Grid> */}
                <Grid item xs={12} className="heading">
                  <Typography className="custom_label" variant="h5">
                    {translation.profile.address}
                  </Typography>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translation.profile.address}
                  </Typography>

                  <Autocomplete
                    onLoad={onLoad}
                    onPlaceChanged={() => onPlaceChanged()}
                  >
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="address"
                      variant="outlined"
                      fullWidth
                      placeholder={translation.profile.address}
                      className="text_field"
                      id="address"
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.address}
                      helperText={
                        formik.touched.address && formik.errors.address
                      }
                    />
                  </Autocomplete>
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translation.profile.city}
                  </Typography>

                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="city"
                    variant="outlined"
                    fullWidth
                    placeholder={translation.profile.place}
                    className="text_field"
                    id="city"
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.city}
                    helperText={formik.touched.city && formik.errors.city}
                  />
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translation.profile.state}
                  </Typography>

                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="state"
                    variant="outlined"
                    fullWidth
                    placeholder={translation.profile.state}
                    className="text_field"
                    id="city"
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.state}
                    helperText={formik.touched.state && formik.errors.state}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translation.profile.country}
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="country"
                    variant="outlined"
                    fullWidth
                    placeholder={translation.profile.country}
                    className="text_field"
                    id="country"
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.country}
                    helperText={formik.touched.country && formik.errors.country}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translation.profile.pin}
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="zipCode"
                    variant="outlined"
                    fullWidth
                    placeholder={translation.profile.pin}
                    className="text_field"
                    id="zipCode"
                    inputProps={{ maxLength: 12 }}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isNumber(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.zipCode}
                    helperText={formik.touched.zipCode && formik.errors.zipCode}
                  />
                </Grid>
                <Grid item xs={12} className="heading">
                  <Typography className="custom_label" variant="h5">
                    {translation.profile.upload}
                  </Typography>
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translation.profile.doc}
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="documentName"
                    variant="outlined"
                    fullWidth
                    placeholder={translation.profile.doc}
                    className="text_field"
                    id="documentName"
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isString(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.documentName}
                    helperText={
                      formik.touched.documentName && formik.errors.documentName
                    }
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translation.profile.date}
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"date"}
                    name="documentExpiryDate"
                    variant="outlined"
                    fullWidth
                    placeholder={translation.profile.date}
                    className="text_field"
                    id="documentExpiryDate"
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.documentExpiryDate}
                    helperText={
                      formik.touched.documentExpiryDate &&
                      formik.errors.documentExpiryDate
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      min: new Date().toISOString().split("T")[0],
                      // Set min to today's date
                      max: "3000-12-31",
                    }}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12} />
                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <Typography className="custom_label">
                    {" "}
                    {translation.profile.doc_img1}
                  </Typography>
                  {frontDocImage ? (
                    <div className="upload_image_preview2">
                      <CardMedia
                        component="img"
                        image={frontDocImage}
                        alt="photo"
                      />
                      <CancelIcon
                        onClick={() => {
                          setFrontDocImage("");
                        }}
                      />
                    </div>
                  ) : (
                    <Box className="upload_image_bnr">
                      <label htmlFor="front-doc">
                        <Input
                          sx={{ display: "none" }}
                          id="front-doc"
                          type="file"
                          inputProps={{
                            accept: "image/png,image/jpeg",
                          }}
                          onChange={(e) =>
                            handleImageUpload(
                              e as ChangeEvent<HTMLInputElement>,
                              "documentFrontImage"
                            )
                          }
                        />
                        <Button component="span" className="upload_image_btn">
                          <AddIcon />
                        </Button>
                      </label>
                    </Box>
                  )}
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <Typography className="custom_label">
                    {translation.profile.doc_img2}
                  </Typography>
                  {backDocImage ? (
                    <div className="upload_image_preview2">
                      <CardMedia
                        component="img"
                        image={backDocImage}
                        alt="photo"
                      />
                      <CancelIcon
                        onClick={() => {
                          setBackDocImage("");
                        }}
                      />
                    </div>
                  ) : (
                    <Box className="upload_image_bnr">
                      <label htmlFor="back-doc">
                        <Input
                          sx={{ display: "none" }}
                          id="back-doc"
                          type="file"
                          inputProps={{
                            accept: "image/png,image/jpeg",
                          }}
                          // onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          //   const files = (event.target as HTMLInputElement)
                          //     .files;
                          //   if (files && files[0].type.includes("image")) {
                          //     setBackDocImage(URL.createObjectURL(files[0]));
                          //   }
                          // }}
                          onChange={(e) =>
                            handleImageUpload(
                              e as ChangeEvent<HTMLInputElement>,
                              "documentBackImage"
                            )
                          }
                        />
                        <Button component="span" className="upload_image_btn">
                          {/* <img
                                src={
                                  image
                                    ? image
                                    : "/static/images/user_placeholder.png"
                                }
                                alt=""
                              /> */}
                          <AddIcon />
                        </Button>
                      </label>
                    </Box>
                  )}
                </Grid>
              </Grid>
              <div className="form_btn">
                <Button
                  size="large"
                  type="submit"
                  className="btn btn_primary"
                  // onClick={() =>{ setError(true)}}
                >
                  {translation.profile.submit}
                </Button>
              </div>
            </CardContent>
          </form>
        </Card>
      </div>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
          setResend(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="auth_parnt">
            <div className="auth_left">
              <h2>{translation.profile.verify_otp}</h2>
              <Box sx={{ paddingBottom: 5 }}></Box>
              <FormControl className="opt_fields" sx={{ width: "100%" }}>
                <OTPInput
                  containerStyle={{ width: "70%", margin: "auto" }}
                  value={otp}
                  onChange={setOtp}
                  numInputs={4}
                  renderInput={(props) => <input {...props} />}
                  inputStyle={otpStyle}
                  inputType="tel"
                />
                <br />
                {error && otp.length !== 4 ? (
                  <h6 className="err_msg" style={{ textAlign: "center" }}>
                    {translation.profile.field}
                  </h6>
                ) : (
                  ""
                )}
              </FormControl>

              <Box sx={{ pt: 3 }}>
                <Button
                  className="btn btn_primary"
                  color="primary"
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={handleSubmit}
                >
                  {translation.profile.verify}
                </Button>
              </Box>
              {countDown === 0 ? (
                <Box
                  sx={{
                    textAlign: "center",
                    pt: 2,
                  }}
                >
                  <Typography
                    className="anchor_link"
                    sx={{ cursor: "pointer", fontWeight: 600 }}
                    onClick={() => {
                      setResend(true);
                      sendOtp();
                    }}
                  >
                    {translation.profile.resend}
                  </Typography>
                </Box>
              ) : (
                <Box>
                  <Typography
                    sx={{
                      color: "grey",
                      pt: 2,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {translation.profile.msg}{" "}
                    <p
                      style={{
                        margin: 0,
                        color: "#d63853",
                        paddingLeft: 3,
                        minWidth: 70,
                      }}
                    >
                      {countDown < 10
                        ? `00: 0${countDown}`
                        : `00: ${countDown}`}
                    </p>
                  </Typography>
                </Box>
              )}
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ProfileSetup;
