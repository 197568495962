import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Typography,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useLazyGetFaqQuery } from "../../services/cms";
import { useEffect, useState } from "react";
import { FaqResponse } from "../../types/General";
import useTranslation from "../../hooks/Translation";
import { STORAGE_KEYS, getFromStorage } from "../../constants";


const Faq = () => {

  const [faqData, setFaqData] = useState<FaqResponse[]>([]);
  const getLang = getFromStorage(STORAGE_KEYS.language);
  const [getFaq, { isLoading }] = useLazyGetFaqQuery();

  const translation = useTranslation() as any;
  const fetchData = async () => {
    try {
      const response = await getFaq({}).unwrap();

      if (response?.statusCode === 200) {
        setFaqData(response?.data?.data || []);
      }
    } catch (error: any) {
      console.log(error, "error=========>");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <MainContainer>
      <div className="main_loyout ">
        <div className="dashboard">
          <h1 className="mn_hdng"> {translation.faq.faq}</h1>
        </div>
        <div>
          <Card className="cards">

            <div className="faq_sc">
              {faqData?.length ? (
                faqData?.map((item) => (

                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p>{getLang==="ar"?item?.question_ar:item?.question || ""}</p>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>{getLang==="ar"?item?.answer_ar:item?.answer || ""}</Typography>
                    </AccordionDetails>
                  </Accordion>
                ))
              ) : (
                <p> {translation.faq.error}</p>
              )}
            </div>


          </Card>
        </div>
      </div>
    </MainContainer>
  );
};

export default Faq;
